import * as React from 'react';
import { useContext, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import { ColorThemeContext } from '@providers/ColorThemeContext';

const pathVariants = {
  hidden: {
    scaleX: 0,
  },
  visible: {
    scaleX: 1,
    transition: {
      duration: 2,
      ease: [0.6, 0.01, -0.05, 0.95],
    },
  },
};

type QuestionFirstIllustrationProps = {
  className?: string
};

export const QuestionFirstIllustration: React.FC<QuestionFirstIllustrationProps> = ({
  className,
}) => {
  const controls = useAnimation();
  const controlsRect = useAnimation();
  const { ref, inView } = useInView({
    threshold: .5,
    triggerOnce: true,
  });
  const { themeColors } = useContext(ColorThemeContext);

  useEffect(() => {
    if (inView) {
      controls.start('visible');
      controlsRect.start({
        scale: 1,
        opacity: [0, .5, 1, 1],
      });
    }
  }, [controls, controlsRect, inView]);

  return (
    <svg ref={ref} className={className} width="327" height="469" viewBox="0 0 327 469" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_287:16059" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="327" height="469">
        <path fillRule="evenodd" clipRule="evenodd" d="M185 284L185 8.10623e-06L2.05006e-05 0L0 469H185H327V284H185Z" fill="#C4C4C4"/>
      </mask>
      <g mask="url(#mask0_287:16059)">
        <motion.line
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          x1="117.229" y1="-114.038" x2="-307.035" y2="310.226" stroke={themeColors.primary} strokeWidth="7"/>
        <motion.line
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          x1="127.127" y1="-104.138" x2="-297.137" y2="320.126" stroke={themeColors.primary} strokeWidth="7"/>
        <motion.line
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          x1="137.03" y1="-94.2387" x2="-287.234" y2="330.025" stroke={themeColors.primary} strokeWidth="7"/>
        <motion.line
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          x1="146.928" y1="-84.3393" x2="-277.336" y2="339.925" stroke={themeColors.primary} strokeWidth="7"/>
        <motion.line
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          x1="156.826" y1="-74.4398" x2="-267.438" y2="349.824" stroke={themeColors.primary} strokeWidth="7"/>
        <motion.line
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          x1="166.725" y1="-64.5403" x2="-257.539" y2="359.724" stroke={themeColors.primary} strokeWidth="7"/>
        <motion.line
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          x1="176.627" y1="-54.6408" x2="-247.637" y2="369.623" stroke={themeColors.primary} strokeWidth="7"/>
        <motion.line
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          x1="186.526" y1="-44.7413" x2="-237.738" y2="379.523" stroke={themeColors.primary} strokeWidth="7"/>
        <motion.line
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          x1="196.424" y1="-34.8418" x2="-227.84" y2="389.422" stroke={themeColors.primary} strokeWidth="7"/>
        <motion.line
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          x1="206.323" y1="-24.9423" x2="-217.941" y2="399.322" stroke={themeColors.primary} strokeWidth="7"/>
        <motion.line
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          x1="216.225" y1="-15.0428" x2="-208.039" y2="409.221" stroke={themeColors.primary} strokeWidth="7"/>
        <motion.line
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          x1="226.123" y1="-5.14329" x2="-198.141" y2="419.121" stroke={themeColors.primary} strokeWidth="7"/>
        <motion.line
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          x1="236.022" y1="4.75618" x2="-188.242" y2="429.02" stroke={themeColors.primary} strokeWidth="7"/>
        <motion.line
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          x1="245.924" y1="14.6557" x2="-178.34" y2="438.92" stroke={themeColors.primary} strokeWidth="7"/>
        <motion.line
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          x1="255.823" y1="24.5552" x2="-168.441" y2="448.819" stroke={themeColors.primary} strokeWidth="7"/>
        <motion.line
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          x1="265.721" y1="34.4547" x2="-158.543" y2="458.719" stroke={themeColors.primary} strokeWidth="7"/>
        <motion.line
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          x1="275.619" y1="44.3541" x2="-148.645" y2="468.618" stroke={themeColors.primary} strokeWidth="7"/>
        <motion.line
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          x1="285.522" y1="54.2536" x2="-138.742" y2="478.518" stroke={themeColors.primary} strokeWidth="7"/>
        <motion.line
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          x1="295.42" y1="64.1532" x2="-128.844" y2="488.417" stroke={themeColors.primary} strokeWidth="7"/>
        <motion.line
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          x1="305.319" y1="74.0526" x2="-118.945" y2="498.317" stroke={themeColors.primary} strokeWidth="7"/>
        <motion.line
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          x1="315.217" y1="83.9521" x2="-109.047" y2="508.216" stroke={themeColors.primary} strokeWidth="7"/>
        <motion.line
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          x1="325.119" y1="93.8516" x2="-99.1446" y2="518.116" stroke={themeColors.primary} strokeWidth="7"/>
        <motion.line
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          x1="335.018" y1="103.751" x2="-89.2462" y2="528.015" stroke={themeColors.primary} strokeWidth="7"/>
        <motion.line
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          x1="344.916" y1="113.651" x2="-79.3477" y2="537.915" stroke={themeColors.primary} strokeWidth="7"/>
        <motion.line
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          x1="354.815" y1="123.55" x2="-69.4493" y2="547.814" stroke={themeColors.primary} strokeWidth="7"/>
        <motion.line
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          x1="364.717" y1="133.45" x2="-59.547" y2="557.714" stroke={themeColors.primary} strokeWidth="7"/>
        <motion.line
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          x1="374.615" y1="143.349" x2="-49.6485" y2="567.613" stroke={themeColors.primary} strokeWidth="7"/>
        <motion.line
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          x1="384.514" y1="153.249" x2="-39.7501" y2="577.513" stroke={themeColors.primary} strokeWidth="7"/>
        <motion.line
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          x1="394.416" y1="163.148" x2="-29.8477" y2="587.412" stroke={themeColors.primary} strokeWidth="7"/>
        <motion.line
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          x1="404.315" y1="173.048" x2="-19.9493" y2="597.312" stroke={themeColors.primary} strokeWidth="7"/>
        <motion.line
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          x1="414.213" y1="182.947" x2="-10.0509" y2="607.211" stroke={themeColors.primary} strokeWidth="7"/>
        <motion.line
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          x1="424.112" y1="192.847" x2="-0.152433" y2="617.111" stroke={themeColors.primary} strokeWidth="7"/>
        <motion.line
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          x1="434.014" y1="202.746" x2="9.74991" y2="627.01" stroke={themeColors.primary} strokeWidth="7"/>
        <motion.line
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          x1="443.912" y1="212.646" x2="19.6483" y2="636.91" stroke={themeColors.primary} strokeWidth="7"/>
        <motion.line
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          x1="453.811" y1="222.545" x2="29.5468" y2="646.809" stroke={themeColors.primary} strokeWidth="7"/>
        <motion.line
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          x1="463.709" y1="232.445" x2="39.4452" y2="656.709" stroke={themeColors.primary} strokeWidth="7"/>
        <motion.line
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          x1="473.612" y1="242.344" x2="49.3476" y2="666.608" stroke={themeColors.primary} strokeWidth="7"/>
        <motion.line
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          x1="483.51" y1="252.244" x2="59.246" y2="676.508" stroke={themeColors.primary} strokeWidth="7"/>
        <motion.line
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          x1="493.408" y1="262.143" x2="69.1444" y2="686.407" stroke={themeColors.primary} strokeWidth="7"/>
        <motion.line
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          x1="503.311" y1="272.043" x2="79.0468" y2="696.307" stroke={themeColors.primary} strokeWidth="7"/>
        <motion.line
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          x1="513.209" y1="281.942" x2="88.9452" y2="706.206" stroke={themeColors.primary} strokeWidth="7"/>
      </g>
      <motion.rect
        initial={{
          scale: 0,
          opacity: 0,
        }}
        animate={controlsRect}
        transition={{
          delay: 1,
          duration: 1,
          ease: [0.6, 0.01, -0.05, 0.95],
        }}
        x="151" y="157" width="112" height="165" fill={themeColors.primary}/>
    </svg>
  );
};