import * as React from 'react';
import { forwardRef } from 'react';

import { Heading } from '@components/common/Heading';
import { Container } from '@components/common/Container';
import { AnimatedLine } from '@components/common/AnimatedLine';

import { ServiceBlock } from './ServiceBlock';
import { ServicesContent } from './content';
import { ServicesIllustration } from './ServicesIllustration';
import * as s from './Services.module.sass';

export const Services = forwardRef<HTMLElement>((_, ref) => (
  <section className={s.root} ref={ref}>
    <AnimatedLine direction='horizontal' />
    <Heading
      title='Services'
      illustration={<ServicesIllustration className={s.headingIllustration} />}
    />
    <Container>
      <AnimatedLine delay={1.2} />
      {ServicesContent.map((service, index) => (
        <ServiceBlock
          key={service.id}
          number={service.id}
          heading={service.title}
          content={service.content}
          last={index === ServicesContent.length - 1}
        />
      ))}
      <AnimatedLine delay={1.4} align='right' className={s.mobile} />
    </Container>
  </section>
));