// extracted by mini-css-extract-plugin
export var question = "QuestionSecondBlock-module--question--3VOrn";
export var mark = "QuestionSecondBlock-module--mark--2XBJD";
export var markInner = "QuestionSecondBlock-module--markInner--2ImLz";
export var questionText = "QuestionSecondBlock-module--questionText--11rOk";
export var answer = "QuestionSecondBlock-module--answer--3pHiC";
export var scrollBlock = "QuestionSecondBlock-module--scrollBlock--3ceZa";
export var questionIllustration = "QuestionSecondBlock-module--questionIllustration--3Jlk9";
export var button = "QuestionSecondBlock-module--button--1DI8L";
export var buttonTextWrapper = "QuestionSecondBlock-module--buttonTextWrapper--2vb54";
export var buttonText = "QuestionSecondBlock-module--buttonText--38BEf";
export var arrowCircle = "QuestionSecondBlock-module--arrowCircle--2dcHw";
export var arrowIcon = "QuestionSecondBlock-module--arrowIcon--1ZIdj";
export var arrowBounce = "QuestionSecondBlock-module--arrowBounce--2Avie";
export var answerBlock = "QuestionSecondBlock-module--answerBlock--2Vhfo";
export var answerText = "QuestionSecondBlock-module--answerText--30-ko";
export var answerIllustration = "QuestionSecondBlock-module--answerIllustration--153dx";
export var buttonTablet = "QuestionSecondBlock-module--buttonTablet--1YmHY";
export var answerBottom = "QuestionSecondBlock-module--answerBottom--tWndD";
export var mobile = "QuestionSecondBlock-module--mobile--28vx3";
export var container = "QuestionSecondBlock-module--container--35YEz";
export var light = "QuestionSecondBlock-module--light--3C3Tv";
export var dark = "QuestionSecondBlock-module--dark--kMBvH";