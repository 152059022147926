// extracted by mini-css-extract-plugin
export var root = "QuestionFirstBlock-module--root--srZIe";
export var question = "QuestionFirstBlock-module--question--3pyNd";
export var questionText = "QuestionFirstBlock-module--questionText--3EMjL";
export var mark = "QuestionFirstBlock-module--mark--1C0xC";
export var answer = "QuestionFirstBlock-module--answer--d0GGH";
export var answerIllustration = "QuestionFirstBlock-module--answerIllustration--1Py2s";
export var answerIllustrationInner = "QuestionFirstBlock-module--answerIllustrationInner--3qpD0";
export var answerText = "QuestionFirstBlock-module--answerText--3cXNc";
export var answerIllustrationInnerMobile = "QuestionFirstBlock-module--answerIllustrationInnerMobile--1yklk";
export var mobile = "QuestionFirstBlock-module--mobile--17Vnm";