// extracted by mini-css-extract-plugin
export var root = "Experience-module--root--2Wnok";
export var year = "Experience-module--year--35nY0";
export var yearLabel = "Experience-module--yearLabel--3J4Yx";
export var year18 = "Experience-module--year18--oenNz";
export var year19 = "Experience-module--year19--32gOI";
export var year20 = "Experience-module--year20--bM3DL";
export var year21 = "Experience-module--year21--BGDPp";
export var project = "Experience-module--project--UD7om";
export var description = "Experience-module--description--1gZsu";
export var descriptionText = "Experience-module--descriptionText--us7LA";
export var descriptionLine = "Experience-module--descriptionLine--1pLbP";
export var project1 = "Experience-module--project1--3gWjC";
export var project2 = "Experience-module--project2--1Mvk4";
export var project3 = "Experience-module--project3--2_UQM";
export var mobile = "Experience-module--mobile--1lcuT";
export var wrapper = "Experience-module--wrapper--3zQDv";
export var desktop = "Experience-module--desktop--1tIf-";
export var light = "Experience-module--light--2h4eN";
export var dark = "Experience-module--dark--3a2PI";