import * as React from 'react';
import cx from 'classnames';

import { SocialLink } from './SocialLink';
import { ContactLinks } from './content';
import * as s from './SocialLinks.module.sass';

type SocialLinksProps = {
  direction?: 'vertical' | 'horizontal'
  size?: 'small'
  className?: string
  linkClassName?: string
};

export const SocialLinks: React.FC<SocialLinksProps> = ({
  size,
  direction = 'vertical',
  className,
  linkClassName,
}) => (
  <div className={cx(s.root, { [s.horizontal]: direction === 'horizontal' }, className)}>
    {ContactLinks.map(({ title, href, id }, i) => (
      <SocialLink
        key={id}
        delay={.3 + .2 * i}
        direction={direction}
        size={size}
        title={title}
        href={href}
        className={cx(s.link, linkClassName)}
      />
    ))}
  </div>
);