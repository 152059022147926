import * as React from 'react';
import { forwardRef, useContext, useEffect, useState } from 'react';
import cx from 'classnames';
import { motion } from 'framer-motion';

import { CursorContext, CursorTypes } from '@providers/CursorProvider';
import { Container } from '@components/common/Container';
import { SocialLinks } from '@components/common/SocialLinks';
import { AnimatedLine } from '@components/common/AnimatedLine';
import LinkArrow from '@icons/LinkArrow.inline.svg';

import { Cat } from './Cat';
import { Clew } from './Clew';
import * as s from './FirstScreen.module.sass';

const marqDelay = 4;

const banner = {
  animate: {
    transition: {
      delayChildren: .8,
      staggerChildren: 0.1,
    },
  },
};

const letterAni = {
  initial: { y: 400 },
  animate: {
    y: 0,
    transition: {
      ease: [0.6, 0.01, -0.05, 0.95],
      duration: 1,
    },
  },
};

type AnimatedLettersProps = {
  title: string,
  disabled?: boolean,
};

const AnimatedLetters: React.FC<AnimatedLettersProps> = ({
  title,
  disabled = false,
}) => (
  <motion.span
    className={s.title}
    variants={disabled ? undefined : banner}
    initial='initial'
    animate='animate'
  >
    {[...title].map((letter, i) => (
      <motion.span
        key={`${letter}-${i}-${i}`}
        className={s.letter}
        variants={disabled ? undefined : letterAni}>
        {letter}
      </motion.span>
    ))}
  </motion.span>
);

type FirstScreenProps = {
  scrollToContactSection: () => void
};

export const FirstScreen = forwardRef<HTMLElement, FirstScreenProps>(
  ({ scrollToContactSection }, ref) => {
    const { toggleCursorType } = useContext(CursorContext);

    const [playMarquee, setPlayMarquee] = useState(false);
    const [isMouseOvered, setIsMouseOvered] = useState(false);

    useEffect(() => {
      setTimeout(() => {
        setPlayMarquee(true);
      }, marqDelay * 1_000);
    }, []);
    
    return (
      <section ref={ref}>
        <Container className={s.container}>
          <AnimatedLine duration={1.4} delay={1.8} fromStart />
          <Clew
            className={s.clew}
            delay={marqDelay - 1}
            isMouseOvered={isMouseOvered}
          />
          <Cat
            className={s.cat}
            delay={marqDelay - 1}
            isMouseOvered={isMouseOvered}
            setIsMouseOvered={setIsMouseOvered}
          />
          <motion.div
            className={s.description}
            initial={{ opacity: 0, y: 80 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              ease: 'easeInOut',
              duration: 1,
              delay: 0.4,
            }}
          >
            Drive action and create connections{' '}
            <br/>
            through branding<span className={s.dashDesktop}>‑</span><span className={s.dashMobile}>-</span>focused copywriting.
          </motion.div>
          <motion.div className={s.mainBlock} variants={banner}>
            <span className={s.firstLine}>
              <AnimatedLetters title={'Website Copy &'} />
            </span>
            <span className={s.secondLine}>
              <AnimatedLetters title={'Brand Messaging for'} />
            </span>
            <div className={s.finalLineWrapper}>
              <span className={cx(s.finalLineInner, { [s.finalLineInnerAnimate]: playMarquee })}>
                <span className={s.finalLineLetters}>
                  <AnimatedLetters title={'entrepreneurs, founders, and creatives.'} disabled />
                </span>
                <span className={s.finalLineLetters}>
                  <AnimatedLetters title={'entrepreneurs, founders, and creatives.'}/>
                </span>
                <span className={s.finalLineLetters}>
                  <AnimatedLetters title={'entrepreneurs, founders, and creatives.'} disabled />
                </span>
              </span>
            </div>
          </motion.div>
          <motion.button
            className={s.button}
            type='button'
            onClick={scrollToContactSection}
            initial={{ opacity: 0, y: 80 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              ease: 'easeInOut',
              duration: 1,
              delay: 2,
            }}
            onMouseEnter={() => toggleCursorType(CursorTypes.pointer)}
            onMouseLeave={() => toggleCursorType(CursorTypes.default)}
          >
            work with me
            <LinkArrow className={s.buttonArrow} />
          </motion.button>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              ease: 'easeInOut',
              duration: 1,
              delay: 2.4,
            }}
          >
            <SocialLinks direction='horizontal' size='small' className={s.links} />
          </motion.div>
          <AnimatedLine duration={1.4} delay={2.2} align='right' fromStart className={s.mobile} />
        </Container>
      </section>
    );
  },
);