import * as React from 'react';
import { useContext } from 'react';
import cx from 'classnames';

import { CursorContext, CursorTypes } from '@providers/CursorProvider';
import { Container } from '@components/common/Container';
import { AnimatedLine } from '@components/common/AnimatedLine';
import { HeadingThird } from '@components/common/HeadingThird';
import { LinkArrow } from '@components/common/LinkArrow';

import { ListContent } from './content';
import * as s from './Problems.module.sass';

type ProblemsProps = {
  scrollToContactSection: () => void
};

export const Problems: React.FC<ProblemsProps> = ({
  scrollToContactSection,
}) => {
  const { toggleCursorType } = useContext(CursorContext);

  return (
    <section className={s.root}>
      <AnimatedLine direction='horizontal' />
      <Container className={s.container}>
        <AnimatedLine delay={.6} />

        <HeadingThird
          lineFirst='Now, when you know a little bit more about me,'
          lineSecond={'let\'s go back to why we are here!'}
          className={s.heading}
          lineSecondClassName={s.headingLine}
        />

        <h4 className={cx(s.boldText, s.question)}>
          IF YOU ARE:
        </h4>
        <ul>
          {ListContent.map((item, i) => (
            <li key={item} className={s.listItem}>
              <LinkArrow delay={.3 + .15 * i} isRotate className={s.listArrow} />
              {item}
            </li>
          ))}
        </ul>
        <button
          className={cx(s.boldText, s.button)}
          type='button'
          onClick={scrollToContactSection}
          onMouseEnter={() => toggleCursorType(CursorTypes.pointer)}
          onMouseLeave={() => toggleCursorType(CursorTypes.default)}
        >
          THEN: we should talk
          <LinkArrow isBold className={s.buttonArrow} />
        </button>
        <div className={s.answer}>
          <p className={s.answerFirst}>
            As a website copywriter, I know how to provide value to your audience and turn your leads into first time buyers.
          </p>
          <p className={s.answerSecond}>
            Remember, our focus is not to sell something off the bat (although we can). Our purpose is to reach THEM and figure out how THEY can identify with your brand. Understanding your ideal audience voice and writing for THEM is our key to success.
          </p>
        </div>
        <AnimatedLine delay={1.4} align='right' className={s.mobile} />
      </Container>
    </section>
  );
};