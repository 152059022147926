import * as React from 'react';
import { useContext, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import { ColorThemeContext } from '@providers/ColorThemeContext';

const pathVariants = {
  hidden: {
    scale: .3,
    rotate: -30,
    opacity: 0,
  },
  visible: (i: number) => ({
    rotate: [-30, 10, 0],
    scale: [.3, 1.05, 1],
    opacity: [0, 1, 1],
    transition: {
      duration: 1,
      delay: .3 * i,
      ease: [0.6, 0.01, -0.05, 0.95],
    },
  }),
};

type QuestionSecondAnswerIllustrationProps = {
  className?: string
};

export const QuestionSecondAnswerIllustration: React.FC<QuestionSecondAnswerIllustrationProps> = ({
  className,
}) => {
  const controls = useAnimation();
  const { ref, inView } = useInView({
    threshold: .5,
    triggerOnce: true,
  });
  const { themeColors } = useContext(ColorThemeContext);

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.svg
      className={className}
      ref={ref} width="320" height="320" viewBox="0 0 320 320" fill="none" xmlns="http://www.w3.org/2000/svg"
    >
      {[0, 1, 2, 3].map((i) => (
        <motion.rect
          key={i}
          x={141.5 - 40 * i}
          y={141.5 - 40 * i}
          width="157"
          height="157"
          stroke={themeColors.primary}
          strokeWidth="3"
          variants={pathVariants}
          initial='hidden'
          animate={controls}
          custom={i}
        />
      ))}
    </motion.svg>
  );
};