import * as React from 'react';
import { forwardRef, useContext } from 'react';
import cx from 'classnames';
import { StaticImage } from 'gatsby-plugin-image';

import { CursorContext, CursorTypes } from '@providers/CursorProvider';
import { Heading } from '@components/common/Heading';
import { Container } from '@components/common/Container';
import { AnimatedLine } from '@components/common/AnimatedLine';
import { LinkArrow } from '@components/common/LinkArrow';

import { MyProjectIllustration } from './MyProjectIllustration';
import * as s from './MyProject.module.sass';

export const MyProject = forwardRef<HTMLElement>(
  (_, ref) => {
    const { toggleCursorType } = useContext(CursorContext);

    return (
      <section ref={ref} className={s.root}>
        <AnimatedLine direction='horizontal' />
        <Heading
          title='My project'
          illustration={<MyProjectIllustration className={s.headingIllustration} />}
        />
        <Container>
          <AnimatedLine delay={1.2} />
          <div className={s.bookBlock}>
            <p className={s.bookTextFirst}>
              “Happy looks good on you” is a journal for self-reflection that has 30 days of questions, little challenges, and tasks that will help you understand yourself just a little bit better, one step at a time. It focuses on the importance of self-love and self-acceptance as it can be truly challenging and overwhelming for some.
            </p>
            <StaticImage
              src="../../../images/Book.png"
              alt="A dinosaur"
              placeholder="blurred"
              className={s.bookImage}
            />
            <p className={s.bookTextSecond}>
              I created this journal for people like me. And I’m putting myself out there. I am not ashamed to show my vulnerabilities, to admit that I am not flawless, to take one step at a time on my journey of self-love. I truly believe in the power of journaling and I’m proud to announce that it is finally ready to see the world!
            </p>
            <a
              href="https://www.lulu.com/en/ie/shop/ilona-kravchenko/happy-looks-good-on-you/paperback/product-m625g9.html?page=1&pageSize=4"
              target='_blank'
              rel="noreferrer noopener"
              className={cx(s.finalButton, s.bookButton)}
              onMouseEnter={() => toggleCursorType(CursorTypes.pointer)}
              onMouseLeave={() => toggleCursorType(CursorTypes.default)}
            >
              Available for purchase on demand
              <LinkArrow className={s.buttonArrow} />
            </a>
          </div>
          <AnimatedLine delay={1.4} align='right' className={s.mobile} />
        </Container>
      </section>
    );
  },
);