import * as React from 'react';
import { forwardRef, useContext, useState } from 'react';
import { Link } from 'gatsby';
import cx from 'classnames';
import { Field, withTypes } from 'react-final-form';
import { FormApi } from 'final-form';
import createDecorator from 'final-form-focus';

import { BUSINESS_NAME_KEY, EMAIL_KEY, NAME_KEY } from '@utils/defaults';
import { composeValidators, required, validateEmail } from '@utils/validators';
import { CursorContext, CursorTypes } from '@providers/CursorProvider';
import { ColorModes, ColorThemeContext } from '@providers/ColorThemeContext';
import { Input } from '@components/ui/Input';
import { Heading } from '@components/common/Heading';
import { Container } from '@components/common/Container';
import { SocialLinks } from '@components/common/SocialLinks';
import { AnimatedLine } from '@components/common/AnimatedLine';
import { HeadingThird } from '@components/common/HeadingThird';
import { LinkArrow } from '@components/common/LinkArrow';

import { Cat } from './Cat';
import { ContactIllustration } from './ContactIllustration';
import * as s from './Contact.module.sass';

const focusOnErrors = createDecorator();

type FormValues = {
  email: string
  name: string
  nameOfBusiness: string
};

const modeClass = {
  [ColorModes.Light]: s.light,
  [ColorModes.Dark]: s.dark,
};

export const Contact = forwardRef<HTMLElement>((_, ref) => {
  const { Form } = withTypes<FormValues>();
  const [formSendingError, setFormSendingError] = useState('');
  const [isAnimate, setIsAnimate] = useState(false);
  const { toggleCursorType } = useContext(CursorContext);
  const { colorThemeMode } = useContext(ColorThemeContext);

  const onSubmit = async (
    values: FormValues,
    form: FormApi<FormValues>,
  ) => {
    const res = await fetch('/api/subscribe-telegram', {
      body: JSON.stringify({
        email: values.email,
        NAME: values.name,
        NAME_OF_BU: values.nameOfBusiness,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });

    const { error } = await res.json();

    if (error) {
      setFormSendingError(`${error}`);
    } else {
      window.localStorage.setItem(NAME_KEY, values.name);
      window.localStorage.setItem(EMAIL_KEY, values.email);
      window.localStorage.setItem(BUSINESS_NAME_KEY, values.nameOfBusiness);

      setIsAnimate(true);
      setTimeout(() => {
        setIsAnimate(false);

        setTimeout(() => setTimeout(form.restart), 1800);
      }, 4000);
    }
  };
  
  return (
    <section className={cx(s.root, modeClass[colorThemeMode])} ref={ref}>
      <AnimatedLine direction='horizontal' />
      <Heading
        title='Contact Information'
        illustration={<ContactIllustration className={s.headingIllustration} />}
      />
      <Container className={s.content}>
        <AnimatedLine delay={1.2} />
        <div className={s.info}>
          <AnimatedLine delay={1.4} align='right' className={s.noTablet} />

          <HeadingThird lineFirst='Let’s find your voice together!' />

          <SocialLinks className={cx(s.links, s.linksDesktop)} />
        </div>
        <Form
          onSubmit={onSubmit}
          // @ts-ignore
          decorators={[focusOnErrors]}
          render={({
            handleSubmit, submitting,
          }) => (
            <form className={s.form} onSubmit={handleSubmit}>
              <Cat isAnimate={isAnimate} className={s.cat} />
              <div className={s.inputs}>
                <div className={cx(s.blur, { [s.blurActive]: isAnimate })} />
                <Field
                  name="name"
                  validate={required}
                >
                  {({ input, meta }) => (
                    <Input
                      {...input}
                      id='name'
                      label='Name'
                      className={s.input}
                      onChange={(e) => {
                        setFormSendingError('');
                        input.onChange(e);
                      }}
                      error={(meta.touched && meta.error) || meta.submitError}
                      onMouseEnter={() => toggleCursorType(CursorTypes.pointer)}
                      onMouseLeave={() => toggleCursorType(CursorTypes.default)}
                    />
                  )}
                </Field>
                <Field
                  name="email"
                  validate={composeValidators(required, validateEmail)}
                >
                  {({ input, meta }) => (
                    <Input
                      {...input}
                      id='email'
                      label='Email'
                      className={s.input}
                      onChange={(e) => {
                        setFormSendingError('');
                        input.onChange(e);
                      }}
                      error={(meta.touched && meta.error) || meta.submitError || formSendingError}
                      onMouseEnter={() => toggleCursorType(CursorTypes.pointer)}
                      onMouseLeave={() => toggleCursorType(CursorTypes.default)}
                    />
                  )}
                </Field>
                <Field
                  name="nameOfBusiness"
                  validate={required}
                >
                  {({ input, meta }) => (
                    <Input
                      {...input}
                      id='nameOfBusiness'
                      label='Name of business'
                      className={s.input}
                      onChange={(e) => {
                        setFormSendingError('');
                        input.onChange(e);
                      }}
                      error={(meta.touched && meta.error) || meta.submitError}
                      onMouseEnter={() => toggleCursorType(CursorTypes.pointer)}
                      onMouseLeave={() => toggleCursorType(CursorTypes.default)}
                    />
                  )}
                </Field>
              </div>
              <div className={s.buttonWrapper}>
                <button
                  type="submit"
                  className={s.arrowLink}
                  disabled={(!formSendingError && submitting) || isAnimate}
                  onMouseEnter={() => toggleCursorType(CursorTypes.pointer)}
                  onMouseLeave={() => toggleCursorType(CursorTypes.default)}
                >
                  {submitting ? 'Submitting' : 'Send'}
                  <LinkArrow className={s.arrowWrapper} />
                </button>
                <div className={s.privacyBlock}>
                  By clicking the button, you accept
                  <Link
                    to='/privacy-policy'
                    className={cx(s.arrowLink, s.privacy)}
                    onMouseEnter={() => toggleCursorType(CursorTypes.pointer)}
                    onMouseLeave={() => toggleCursorType(CursorTypes.default)}
                  >
                    the privacy policy
                    <LinkArrow className={s.arrowWrapper} />
                  </Link>
                </div>
              </div>
            </form>
          )}
        />

        <SocialLinks
          className={cx(s.links, s.linksMobile)}
          linkClassName={s.socialLink}
          direction='horizontal'
        />

        <AnimatedLine delay={1.4} align='right' className={s.mobile} />
      </Container>
    </section>
  );
});