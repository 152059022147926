import * as React from 'react';
import { useContext, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation, Variants } from 'framer-motion';
import cx from 'classnames';

import { ColorModes, ColorThemeContext } from '@providers/ColorThemeContext';

// Main line variants
const mineLineVariantsDesktop = {
  hidden: {
    width: 0,
  },
  visible: {
    width: '100%',
    transition: {
      duration: 1,
      delay: .3,
      ease: [0.6, 0.01, -0.05, 0.95],
    },
  },
};
const mineLineVariantsMobile = {
  hidden: {
    height: 0,
  },
  visible: {
    height: '100%',
    transition: {
      duration: 1,
      delay: .3,
      ease: [0.6, 0.01, -0.05, 0.95],
    },
  },
};

// Year line variants
const yearLineVariantsDesktop = {
  hidden: {
    height: 0,
  },
  visible: (i: number) => ({
    height: '8rem',
    transition: {
      duration: .3,
      delay: 1 + .3 * (i + 1),
      ease: [0.6, 0.01, -0.05, 0.95],
    },
  }),
};
const yearLineVariantsMobile = {
  hidden: {
    width: 0,
  },
  visible: (i: number) => ({
    width: '8rem',
    transition: {
      duration: .3,
      delay: 1 + .3 * (i + 1),
      ease: [0.6, 0.01, -0.05, 0.95],
    },
  }),
};

// Year text variants
const yearTextVariantsCommon = {
  hidden: {
    opacity: 0,
    scale: .2,
  },
  visible: (i: number) => ({
    opacity: 1,
    scale: [.2, 1.1, 1],
    transition: {
      duration: .4,
      delay: 1 + .3 + .3 * (i + 1),
      ease: [0.6, 0.01, -0.05, 0.95],
    },
  }),
};

// Project blocks variants
const projectBLockDimensions = [
  '37.5%',
  '45.833%',
  '66.666%',
];
const projectBlockVariantsDesktop = {
  hidden: {
    width: 0,
  },
  visible: (i: number) => ({
    width: projectBLockDimensions[i],
    transition: {
      duration: .4,
      delay: 1 + .3 + .6 + .3 * (i + 1),
      ease: [0.6, 0.01, -0.05, 0.95],
    },
  }),
};
const projectBlockVariantsMobile = {
  hidden: {
    height: 0,
  },
  visible: (i: number) => ({
    height: projectBLockDimensions[i],
    transition: {
      duration: .4,
      delay: 1 + .3 + .6 + .3 * (i + 1),
      ease: [0.6, 0.01, -0.05, 0.95],
    },
  }),
};

// Project Description Line Variants
const projectDescriptionLineDimensions = [
  '8.3rem',
  '7rem',
  '9rem',
];
const projectDescriptionLineVariantsDesktop = {
  hidden: {
    height: 0,
  },
  visible: (i: number) => ({
    height: projectDescriptionLineDimensions[i],
    transition: {
      duration: .4,
      delay: 1 + .3 + .6 + .3 + .3 * (i + 1),
      ease: [0.6, 0.01, -0.05, 0.95],
    },
  }),
};
const projectDescriptionLineVariantsMobile = {
  hidden: {
    width: 0,
  },
  visible: (i: number) => ({
    width: `calc(${projectDescriptionLineDimensions[i]} - 3px)`,
    transition: {
      duration: .4,
      delay: 1 + .3 + .6 + .3 + .3 * (i + 1),
      ease: [0.6, 0.01, -0.05, 0.95],
    },
  }),
};

// Project Description Text Variants
const projectDescriptionTextVariantsCommon = {
  hidden: {
    opacity: 0,
    scale: .2,
  },
  visible: (i: number) => ({
    opacity: 1,
    scale: [.2, 1.1, 1],
    transition: {
      duration: .4,
      delay: 1 + .3 + .6 + .3 + .3 + .3 * (i + 1),
      ease: [0.6, 0.01, -0.05, 0.95],
    },
  }),
};

import * as s from './Experience.module.sass';

type ExperienceInnerProps = {
  mainLineVariants: Variants
  yearLineVariants: Variants
  yearTextVariants: Variants
  projectBlockVariants: Variants
  projectDescriptionLineVariants: Variants
  projectDescriptionTextVariants: Variants
  className?: string
};

const modeClass = {
  [ColorModes.Light]: s.light,
  [ColorModes.Dark]: s.dark,
};

const ExperienceInner: React.FC<ExperienceInnerProps> = ({
  mainLineVariants,
  yearLineVariants,
  yearTextVariants,
  projectBlockVariants,
  projectDescriptionLineVariants,
  projectDescriptionTextVariants,
  className,
}) => {
  const controlsMainLine = useAnimation();
  const controlsYearLine = useAnimation();
  const controlsYearText = useAnimation();
  const controlsProjectBlock = useAnimation();
  const controlsProjectDescriptionLine = useAnimation();
  const controlsProjectDescriptionText = useAnimation();
  const { ref, inView } = useInView({
    threshold: .7,
    triggerOnce: true,
  });
  const { colorThemeMode } = useContext(ColorThemeContext);

  useEffect(() => {
    if (inView) {
      controlsMainLine.start('visible');
      controlsYearLine.start('visible');
      controlsYearText.start('visible');
      controlsProjectBlock.start('visible');
      controlsProjectDescriptionLine.start('visible');
      controlsProjectDescriptionText.start('visible');
    }
  }, [
    controlsMainLine,
    controlsProjectBlock,
    controlsYearLine,
    controlsYearText,
    controlsProjectDescriptionLine,
    controlsProjectDescriptionText,
    inView,
  ]);

  return (
    <div className={cx(s.wrapper, modeClass[colorThemeMode], className)}>
      <motion.div
        className={s.root}
        ref={ref}
        initial='hidden'
        animate={controlsMainLine}
        variants={mainLineVariants}
      >
        <motion.div
          initial='hidden'
          animate={controlsYearLine}
          variants={yearLineVariants}
          custom={0}
          className={cx(s.year, s.year18)}
        >
          <motion.span
            initial='hidden'
            animate={controlsYearText}
            variants={yearTextVariants}
            custom={0}
            className={s.yearLabel}
          >
            {'\'18'}
          </motion.span>
        </motion.div>
        <motion.div
          initial='hidden'
          animate={controlsYearLine}
          variants={yearLineVariants}
          custom={1}
          className={cx(s.year, s.year19)}
        >
          <motion.span
            initial='hidden'
            animate={controlsYearText}
            variants={yearTextVariants}
            custom={1}
            className={s.yearLabel}
          >
            {'\'19'}
          </motion.span>
        </motion.div>
        <motion.div
          initial='hidden'
          animate={controlsYearLine}
          variants={yearLineVariants}
          custom={2}
          className={cx(s.year, s.year20)}
        >
          <motion.span
            initial='hidden'
            animate={controlsYearText}
            variants={yearTextVariants}
            custom={2}
            className={s.yearLabel}
          >
            {'\'20'}
          </motion.span>
        </motion.div>
        <motion.div
          initial='hidden'
          animate={controlsYearLine}
          variants={yearLineVariants}
          custom={3}
          className={cx(s.year, s.year21)}
        >
          <motion.span
            initial='hidden'
            animate={controlsYearText}
            variants={yearTextVariants}
            custom={3}
            className={s.yearLabel}
          >
            {'\'21'}
          </motion.span>
        </motion.div>
        <motion.div
          initial='hidden'
          animate={controlsProjectBlock}
          variants={projectBlockVariants}
          custom={0}
          className={cx(s.project, s.project1)}
        >
          <span className={s.description}>
            <motion.span
              initial='hidden'
              animate={controlsProjectDescriptionText}
              variants={projectDescriptionTextVariants}
              custom={0}
              className={s.descriptionText}
            >
              FRONT‑END MANAGER
            </motion.span>
            <motion.span
              initial='hidden'
              animate={controlsProjectDescriptionLine}
              variants={projectDescriptionLineVariants}
              custom={0}
              className={s.descriptionLine}
            />
          </span>
        </motion.div>
        <motion.div
          initial='hidden'
          animate={controlsProjectBlock}
          variants={projectBlockVariants}
          custom={1}
          className={cx(s.project, s.project2)}
        >
          <span className={s.description}>
            <motion.span
              initial='hidden'
              animate={controlsProjectDescriptionText}
              variants={projectDescriptionTextVariants}
              custom={1}
              className={s.descriptionText}
            >
              Paralegal
            </motion.span>
            <motion.span
              initial='hidden'
              animate={controlsProjectDescriptionLine}
              variants={projectDescriptionLineVariants}
              custom={1}
              className={s.descriptionLine}
            />
          </span>
        </motion.div>
        <motion.div
          initial='hidden'
          animate={controlsProjectBlock}
          variants={projectBlockVariants}
          custom={2}
          className={cx(s.project, s.project3)}
        >
          <span className={s.description}>
            <motion.span
              initial='hidden'
              animate={controlsProjectDescriptionText}
              variants={projectDescriptionTextVariants}
              custom={2}
              className={s.descriptionText}
            >
              Freelance writer
            </motion.span>
            <motion.span
              initial='hidden'
              animate={controlsProjectDescriptionLine}
              variants={projectDescriptionLineVariants}
              custom={2}
              className={s.descriptionLine}
            />
          </span>
        </motion.div>
      </motion.div>
    </div>
  );
};

export const Experience = () => (
  <>
    <ExperienceInner
      mainLineVariants={mineLineVariantsDesktop}
      yearLineVariants={yearLineVariantsDesktop}
      yearTextVariants={yearTextVariantsCommon}
      projectBlockVariants={projectBlockVariantsDesktop}
      projectDescriptionLineVariants={projectDescriptionLineVariantsDesktop}
      projectDescriptionTextVariants={projectDescriptionTextVariantsCommon}
      className={s.desktop}
    />
    <ExperienceInner
      mainLineVariants={mineLineVariantsMobile}
      yearLineVariants={yearLineVariantsMobile}
      yearTextVariants={yearTextVariantsCommon}
      projectBlockVariants={projectBlockVariantsMobile}
      projectDescriptionLineVariants={projectDescriptionLineVariantsMobile}
      projectDescriptionTextVariants={projectDescriptionTextVariantsCommon}
      className={s.mobile}
    />
  </>
);