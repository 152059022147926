import * as React from 'react';
import { forwardRef, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import { Container } from '@components/common/Container';
import { AnimatedLine } from '@components/common/AnimatedLine';
import { HeadingThird } from '@components/common/HeadingThird';

import { QuestionFirstIllustration } from './QuestionFirstIllustration';
import { QuestionFirstIllustrationMobile } from './QuestionFirstIllustrationMobile';
import * as s from './QuestionFirstBlock.module.sass';

export const QuestionFirstBlock = forwardRef<HTMLElement>((_, ref) => {
  const controls = useAnimation();

  const { ref: markRef, inView } = useInView({
    threshold: .5,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      controls.start({
        rotate: [85, -15, 0],
        scale: [0, 1.1, 1],
        opacity: 1,
      });
    }
  }, [controls, inView]);

  return (
    <section className={s.root} ref={ref}>
      <AnimatedLine direction='horizontal' />
      <Container>
        <AnimatedLine duration={1.6} />
        <div className={s.question}>
          <HeadingThird
            lineFirst='Did you know that increasing your website conversion ultimately affects meeting your business goals?'
            className={s.questionText}
          />
          <div className={s.mark}>
            <AnimatedLine delay={.6} />
            <motion.span
              ref={markRef}
              initial={{
                rotate: 85,
                opacity: 0,
                scale: 0,
              }}
              animate={controls}
              transition={{
                ease: [0.6, 0.01, -0.05, 0.95],
                duration: 1,
                delay: .3,
              }}
            >
              ?
            </motion.span>
          </div>
          <AnimatedLine direction='horizontal' verticalAlign='bottom' delay={.4} />
        </div>
        <div className={s.answer}>
          <div className={s.answerIllustration}>
            <QuestionFirstIllustration className={s.answerIllustrationInner} />
          </div>
          <div className={s.answerText}>
            <AnimatedLine duration={1.3} delay={.9} />
            <p>
              Yes, it does!
            </p>
            <p>
              Your website is your sales engine. It drives your business forward while you are sleeping.
            </p>
            <p>
              However, if your brand communication is poor, you might actually end up losing more than you can afford. Whether it is direct revenue or qualifying leads, such loss can be detrimental to your success.
            </p>
          </div>
          <QuestionFirstIllustrationMobile className={s.answerIllustrationInnerMobile} />
        </div>
        <AnimatedLine duration={1.6} delay={1.4} align='right' className={s.mobile} />
      </Container>
    </section>
  );
});