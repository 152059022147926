import * as React from 'react';
import { useContext, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import { ColorThemeContext } from '@providers/ColorThemeContext';

const pathVariants = {
  hidden: {
    scaleY: 0,
  },
  visible: {
    scaleY: 1,
    transition: {
      duration: 2,
      ease: [0.6, 0.01, -0.05, 0.95],
    },
  },
};

type QuestionFirstIllustrationProps = {
  className?: string
};

export const QuestionFirstIllustrationMobile: React.FC<QuestionFirstIllustrationProps> = ({
  className,
}) => {
  const controls = useAnimation();
  const controlsRect = useAnimation();
  const { ref, inView } = useInView({
    threshold: .5,
    triggerOnce: true,
  });
  const { themeColors } = useContext(ColorThemeContext);

  useEffect(() => {
    if (inView) {
      controls.start('visible');
      controlsRect.start({
        scale: 1,
        opacity: [0, .5, 1, 1],
      });
    }
  }, [controls, controlsRect, inView]);

  return (
    <svg ref={ref} className={className} width="371" height="132" viewBox="0 0 371 132" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_250:3274" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="18" width="328" height="114">
        <path d="M0 18H328V132H0V18Z" fill="#C4C4C4"/>
      </mask>
      <g mask="url(#mask0_250:3274)">
        <motion.path
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          fillRule="evenodd" clipRule="evenodd" d="M-101.623 126.815L167.323 -142.131L170.461 -138.994L-98.4857 129.953L-101.623 126.815Z" fill={themeColors.primary}/>
        <motion.path
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          fillRule="evenodd" clipRule="evenodd" d="M-95.348 133.091L173.599 -135.856L176.736 -132.718L-92.2103 136.229L-95.348 133.091Z" fill={themeColors.primary}/>
        <motion.path
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          fillRule="evenodd" clipRule="evenodd" d="M-89.0727 139.366L179.874 -129.58L183.012 -126.443L-85.9349 142.504L-89.0727 139.366Z" fill={themeColors.primary}/>
        <motion.path
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          fillRule="evenodd" clipRule="evenodd" d="M-82.7953 145.642L186.151 -123.305L189.289 -120.167L-79.6576 148.779L-82.7953 145.642Z" fill={themeColors.primary}/>
        <motion.path
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          fillRule="evenodd" clipRule="evenodd" d="M-76.5238 151.917L192.423 -117.03L195.561 -113.892L-73.3861 155.055L-76.5238 151.917Z" fill={themeColors.primary}/>
        <motion.path
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          fillRule="evenodd" clipRule="evenodd" d="M-70.2445 158.193L198.702 -110.754L201.84 -107.616L-67.1068 161.33L-70.2445 158.193Z" fill={themeColors.primary}/>
        <motion.path
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          fillRule="evenodd" clipRule="evenodd" d="M-63.9711 164.468L204.976 -104.479L208.113 -101.341L-60.8334 167.606L-63.9711 164.468Z" fill={themeColors.primary}/>
        <motion.path
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          fillRule="evenodd" clipRule="evenodd" d="M-57.6957 170.743L211.251 -98.2034L214.389 -95.0657L-54.558 173.881L-57.6957 170.743Z" fill={themeColors.primary}/>
        <motion.path
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          fillRule="evenodd" clipRule="evenodd" d="M-51.4203 177.019L217.526 -91.928L220.664 -88.7903L-48.2826 180.156L-51.4203 177.019Z" fill={themeColors.primary}/>
        <motion.path
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          fillRule="evenodd" clipRule="evenodd" d="M-45.1449 183.294L223.802 -85.6525L226.939 -82.5148L-42.0072 186.432L-45.1449 183.294Z" fill={themeColors.primary}/>
        <motion.path
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          fillRule="evenodd" clipRule="evenodd" d="M-38.8676 189.57L230.079 -79.3771L233.217 -76.2394L-35.7299 192.707L-38.8676 189.57Z" fill={themeColors.primary}/>
        <motion.path
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          fillRule="evenodd" clipRule="evenodd" d="M-32.5922 195.845L236.354 -73.1017L239.492 -69.964L-29.4545 198.983L-32.5922 195.845Z" fill={themeColors.primary}/>
        <motion.path
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          fillRule="evenodd" clipRule="evenodd" d="M-26.3168 202.12L242.63 -66.8263L245.768 -63.6886L-23.1791 205.258L-26.3168 202.12Z" fill={themeColors.primary}/>
        <motion.path
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          fillRule="evenodd" clipRule="evenodd" d="M-20.0414 208.396L248.905 -60.5509L252.043 -57.4132L-16.9037 211.533L-20.0414 208.396Z" fill={themeColors.primary}/>
        <motion.path
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          fillRule="evenodd" clipRule="evenodd" d="M-13.768 214.671L255.179 -54.2754L258.316 -51.1377L-10.6303 217.809L-13.768 214.671Z" fill={themeColors.primary}/>
        <motion.path
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          fillRule="evenodd" clipRule="evenodd" d="M-7.49258 220.947L261.454 -47.9999L264.592 -44.8622L-4.35486 224.085L-7.49258 220.947Z" fill={themeColors.primary}/>
        <motion.path
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          fillRule="evenodd" clipRule="evenodd" d="M-1.21718 227.222L267.729 -41.7245L270.867 -38.5868L1.92053 230.36L-1.21718 227.222Z" fill={themeColors.primary}/>
        <motion.path
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          fillRule="evenodd" clipRule="evenodd" d="M5.05821 233.497L274.005 -35.4492L277.143 -32.3115L8.19592 236.635L5.05821 233.497Z" fill={themeColors.primary}/>
        <motion.path
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          fillRule="evenodd" clipRule="evenodd" d="M11.3355 239.773L280.282 -29.1738L283.42 -26.0361L14.4733 242.911L11.3355 239.773Z" fill={themeColors.primary}/>
        <motion.path
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          fillRule="evenodd" clipRule="evenodd" d="M17.609 246.048L286.556 -22.8982L289.693 -19.7605L20.7467 249.186L17.609 246.048Z" fill={themeColors.primary}/>
        <motion.path
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          fillRule="evenodd" clipRule="evenodd" d="M23.8863 252.324L292.833 -16.6228L295.971 -13.4851L27.024 255.462L23.8863 252.324Z" fill={themeColors.primary}/>
        <motion.path
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          fillRule="evenodd" clipRule="evenodd" d="M30.1617 258.599L299.108 -10.3474L302.246 -7.20972L33.2994 261.737L30.1617 258.599Z" fill={themeColors.primary}/>
        <motion.path
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          fillRule="evenodd" clipRule="evenodd" d="M36.4352 264.875L305.382 -4.07204L308.52 -0.934326L39.5729 268.012L36.4352 264.875Z" fill={themeColors.primary}/>
        <motion.path
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          fillRule="evenodd" clipRule="evenodd" d="M42.7105 271.15L311.657 2.20335L314.795 5.34106L45.8483 274.288L42.7105 271.15Z" fill={themeColors.primary}/>
        <motion.path
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          fillRule="evenodd" clipRule="evenodd" d="M48.9859 277.426L317.933 8.47887L321.07 11.6166L52.1237 280.563L48.9859 277.426Z" fill={themeColors.primary}/>
        <motion.path
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          fillRule="evenodd" clipRule="evenodd" d="M55.2633 283.701L324.21 14.7543L327.348 17.892L58.401 286.839L55.2633 283.701Z" fill={themeColors.primary}/>
        <motion.path
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          fillRule="evenodd" clipRule="evenodd" d="M61.5387 289.976L330.485 21.0296L333.623 24.1674L64.6764 293.114L61.5387 289.976Z" fill={themeColors.primary}/>
        <motion.path
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          fillRule="evenodd" clipRule="evenodd" d="M67.8141 296.252L336.761 27.305L339.898 30.4427L70.9518 299.389L67.8141 296.252Z" fill={themeColors.primary}/>
        <motion.path
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          fillRule="evenodd" clipRule="evenodd" d="M74.0895 302.527L343.036 33.5807L346.174 36.7184L77.2272 305.665L74.0895 302.527Z" fill={themeColors.primary}/>
        <motion.path
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          fillRule="evenodd" clipRule="evenodd" d="M80.3648 308.803L349.312 39.8561L352.449 42.9938L83.5026 311.94L80.3648 308.803Z" fill={themeColors.primary}/>
        <motion.path
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          fillRule="evenodd" clipRule="evenodd" d="M86.6383 315.078L355.585 46.1315L358.723 49.2692L89.776 318.216L86.6383 315.078Z" fill={themeColors.primary}/>
        <motion.path
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          fillRule="evenodd" clipRule="evenodd" d="M92.9137 321.354L361.86 52.4068L364.998 55.5446L96.0514 324.491L92.9137 321.354Z" fill={themeColors.primary}/>
        <motion.path
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          fillRule="evenodd" clipRule="evenodd" d="M99.1891 327.629L368.136 58.6821L371.273 61.8198L102.327 330.767L99.1891 327.629Z" fill={themeColors.primary}/>
        <motion.path
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          fillRule="evenodd" clipRule="evenodd" d="M105.466 333.904L374.413 64.9577L377.551 68.0955L108.604 337.042L105.466 333.904Z" fill={themeColors.primary}/>
        <motion.path
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          fillRule="evenodd" clipRule="evenodd" d="M111.742 340.18L380.688 71.2331L383.826 74.3708L114.88 343.318L111.742 340.18Z" fill={themeColors.primary}/>
      </g>
      <motion.path
        initial={{
          scale: 0,
          opacity: 0,
        }}
        animate={controlsRect}
        transition={{
          delay: 1,
          duration: 1,
          ease: [0.6, 0.01, -0.05, 0.95],
        }}
        d="M253 82L253 0L371 1.40714e-06L371 82L253 82Z" fill={themeColors.primary}
      />
    </svg>
  );
};