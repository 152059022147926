import * as React from 'react';
import { useContext, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import cx from 'classnames';

import { CursorContext, CursorTypes } from '@providers/CursorProvider';
import { ColorModes, ColorThemeContext } from '@providers/ColorThemeContext';
import { Container } from '@components/common/Container';
import { AnimatedLine } from '@components/common/AnimatedLine';
import { HeadingThird } from '@components/common/HeadingThird';
import ArrowDown from '@icons/ArrowDown.inline.svg';

import { QuestionSecondAnswerIllustration } from './QuestionSecondAnswerIllustration';
import { QuestionSecondQuestionIllustration } from './QuestionSecondQuestionIllustration';
import * as s from './QuestionSecondBlock.module.sass';

const pathVariants = {
  hidden: (i: number) => ({
    x: -i * 7,
    scale: .5,
    opacity: 0,
  }),
  visible: (i: number) => ({
    x: 0,
    scale: 1,
    opacity: [0, 1, 1],
    transition: {
      duration: .4,
      delay: .2 * i,
      ease: [0.6, 0.01, -0.05, 0.95],
    },
  }),
};

const Marks: React.FC = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView({
    threshold: .5,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <div ref={ref} className={s.mark}>
      {[0, 1, 2, 3].map((i) => (
        <motion.span
          key={i}
          initial='hidden'
          animate={controls}
          variants={pathVariants}
          custom={4 - i}
          className={s.markInner}
        >
          {'>'}
        </motion.span>
      ))}
    </div>
  );
};

type QuestionSecondBlockProps = {
  scrollToAboutSection: () => void
};

const modeClass = {
  [ColorModes.Light]: s.light,
  [ColorModes.Dark]: s.dark,
};

export const QuestionSecondBlock: React.FC<QuestionSecondBlockProps> = ({
  scrollToAboutSection,
}) => {
  const { toggleCursorType } = useContext(CursorContext);
  const { colorThemeMode } = useContext(ColorThemeContext);

  const controlsText = useAnimation();
  const controlsCircle = useAnimation();
  const { ref, inView } = useInView({
    threshold: .5,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      controlsText.start({
        opacity: 1,
        x: 0,
        transition: {
          duration: 1,
          delay: 0,
          ease: [0.6, 0.01, -0.05, 0.95],
        },
      });
      controlsCircle.start({
        opacity: 1,
        scale: [.2, 1.05, 1],
        transition:{
          duration: 1,
          delay: .4,
          ease: [0.6, 0.01, -0.05, 0.95],
        },
      });
    }
  }, [controlsCircle, controlsText, inView]);
  
  return (
    <section className={modeClass[colorThemeMode]}>
      <Container className={s.container}>
        <AnimatedLine />
        <div className={s.question}>
          <AnimatedLine direction='horizontal' />
          <Marks />
          <HeadingThird
            lineFirst='You may be thinking,'
            lineSecond='"Wait a minute, so how do I fix it?"'
            className={s.questionText}
          />
          <AnimatedLine direction='horizontal' verticalAlign='bottom' delay={.3} />
        </div>
        <div className={s.answer}>
          <div className={s.scrollBlock}>
            <QuestionSecondQuestionIllustration className={s.questionIllustration} />
            <button
              type='button'
              className={s.button}
              onClick={scrollToAboutSection}
              onMouseEnter={() => toggleCursorType(CursorTypes.pointer)}
              onMouseLeave={() => toggleCursorType(CursorTypes.default)}
              ref={ref}
            >
              <span className={s.buttonTextWrapper}>
                <motion.span
                  className={s.buttonText}
                  animate={controlsText}
                  initial={{
                    opacity: 0,
                    x: 100,
                  }}
                >
                  scroll down
                  <br />
                  to learn more
                </motion.span>
              </span>
              <motion.div
                className={s.arrowCircle}
                animate={controlsCircle}
                initial={{
                  opacity: 0,
                  scale: .2,
                }}
              >
                <ArrowDown className={s.arrowIcon}/>
              </motion.div>
            </button>
          </div>
          <div className={s.answerBlock}>
            <div className={s.answerText}>
              <p>
                The answer is simple.
              </p>
              <p>
                WE find your strategic balance, come up with the right words that will resonate with your audience and show them why you are so valuable.
              </p>
              <p>
                Words create connection.
              </p>
              <p>
                Establishing a clear vision of your value and writing ROI-driving website copy can make all the difference in the world.
              </p>
              <p>
                And if you are struggling with finding the right path for your brand messaging, I am here for you!
              </p>
              <p>
                I will help you create an authentic connection with your audience and empower your business through a persuasive high-quality copy.
              </p>
            </div>
            <div className={s.answerBottom}>
              <button
                type='button'
                className={cx(s.button, s.buttonTablet)}
                onClick={scrollToAboutSection}
                onMouseEnter={() => toggleCursorType(CursorTypes.pointer)}
                onMouseLeave={() => toggleCursorType(CursorTypes.default)}
              >
                <span className={s.buttonText}>
                  scroll down
                  <br />
                  to learn more
                </span>
                <div className={s.arrowCircle}>
                  <ArrowDown className={s.arrowIcon}/>
                </div>
              </button>
              <QuestionSecondAnswerIllustration className={s.answerIllustration} />
            </div>
          </div>
        </div>
        <AnimatedLine delay={1.2} align='right' className={s.mobile} />
      </Container>
    </section>
  );
};