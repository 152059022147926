export const ContactLinks = [
  {
    id: 1,
    title: 'Linkedin',
    href: 'https://www.linkedin.com/in/ilonakravchenko',
  },
  {
    id: 2,
    title: 'Telegram',
    href: 'https://T.me/IlonaSKravchenko',
  },
  {
    id: 3,
    title: 'Whatsapp',
    href: 'https://wa.me/18432515622',
  },
];