import * as React from 'react';
import { ReactElement } from 'react';
import cx from 'classnames';

import { AnimatedLine } from '@components/common/AnimatedLine';

import * as s from './ServiceBlock.module.sass';

type ServiceBlockProps = {
  number: number
  heading: string
  content: ReactElement
  last?: boolean
};

export const ServiceBlock: React.FC<ServiceBlockProps> = ({
  number,
  heading,
  content,
  last,
}) => (
  <div className={cx(s.root, { [s.last]: last })}>
    <div className={s.headingWrapper}>
      {/* eslint-disable-next-line react/jsx-no-comment-textnodes */}
      <span className={s.number}>
        // 0{number}
      </span>
      <h3 className={s.heading}>
        {heading}
      </h3>
    </div>
    <div className={s.content}>
      <AnimatedLine delay={1.4} />
      <AnimatedLine direction='horizontal' verticalAlign='bottom' delay={.3} className={s.lineBottom} />
      {content}
    </div>
  </div>
);