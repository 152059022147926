import * as React from 'react';
import { useContext, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import { ColorThemeContext } from '@providers/ColorThemeContext';

const pathVariants = {
  hidden: {
    scaleY: 0,
  },
  visible: (i: number) => ({
    scaleY: 1,
    transition: {
      duration: .6,
      delay: .3 * i,
      ease: [0.6, 0.01, -0.05, 0.95],
    },
  }),
};

type MyProjectIllustrationProps = {
  className?: string
};

export const MyProjectIllustration: React.FC<MyProjectIllustrationProps> = ({
  className,
}) => {
  const controls = useAnimation();
  const { ref, inView } = useInView({
    threshold: .5,
    triggerOnce: true,
  });
  const { themeColors } = useContext(ColorThemeContext);

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <svg ref={ref} className={className} width="489" height="89" viewBox="0 0 489 89" fill="none" xmlns="http://www.w3.org/2000/svg">
      <motion.rect
        initial='hidden'
        variants={pathVariants}
        animate={controls}
        custom={0}
        style={{ originX: '50%', originY: 0 }}
        width="18" height="89" fill={themeColors.primary}
      />
      <motion.rect
        initial='hidden'
        variants={pathVariants}
        animate={controls}
        custom={1}
        style={{ originX: '50%', originY: 0 }}
        x="22" width="3" height="89" fill={themeColors.primary}
      />
      <motion.rect
        initial='hidden'
        variants={pathVariants}
        animate={controls}
        custom={2}
        style={{ originX: '50%', originY: 1 }}
        x="29" width="6" height="89" fill={themeColors.primary}
      />
      <motion.rect
        initial='hidden'
        variants={pathVariants}
        animate={controls}
        custom={1}
        style={{ originX: '50%', originY: 0 }}
        x="39" width="3" height="89" fill={themeColors.primary}
      />
      <motion.rect
        initial='hidden'
        variants={pathVariants}
        animate={controls}
        custom={3}
        style={{ originX: '50%', originY: 1 }}
        x="46" width="3" height="89" fill={themeColors.primary}
      />
      <motion.rect
        initial='hidden'
        variants={pathVariants}
        animate={controls}
        custom={5}
        style={{ originX: '50%', originY: 1 }}
        x="70" width="9" height="89" fill={themeColors.primary}
      />
      <motion.rect
        initial='hidden'
        variants={pathVariants}
        animate={controls}
        custom={1}
        style={{ originX: '50%', originY: 1 }}
        x="83" width="9" height="89" fill={themeColors.primary}
      />
      <motion.rect
        initial='hidden'
        variants={pathVariants}
        animate={controls}
        custom={0}
        style={{ originX: '50%', originY: 0 }}
        x="96" width="6" height="89" fill={themeColors.primary}
      />
      <motion.rect
        initial='hidden'
        variants={pathVariants}
        animate={controls}
        custom={3}
        style={{ originX: '50%', originY: 0 }}
        x="106" width="3" height="89" fill={themeColors.primary}
      />
      <motion.rect
        initial='hidden'
        variants={pathVariants}
        animate={controls}
        custom={4}
        style={{ originX: '50%', originY: 1 }}
        x="130" width="9" height="89" fill={themeColors.primary}
      />
      <motion.rect
        initial='hidden'
        variants={pathVariants}
        animate={controls}
        custom={5}
        style={{ originX: '50%', originY: 0 }}
        x="143" width="23" height="89" fill={themeColors.primary}
      />
      <motion.rect
        initial='hidden'
        variants={pathVariants}
        animate={controls}
        custom={1}
        style={{ originX: '50%', originY: 1 }}
        x="170" width="23" height="89" fill={themeColors.primary}
      />
      <motion.rect
        initial='hidden'
        variants={pathVariants}
        animate={controls}
        custom={5}
        style={{ originX: '50%', originY: 0 }}
        x="197" width="3" height="89" fill={themeColors.primary}
      />
      <motion.rect
        initial='hidden'
        variants={pathVariants}
        animate={controls}
        custom={0}
        style={{ originX: '50%', originY: 1 }}
        x="204" width="6" height="89" fill={themeColors.primary}
      />
      <motion.rect
        initial='hidden'
        variants={pathVariants}
        animate={controls}
        custom={4}
        style={{ originX: '50%', originY: 0 }}
        x="214" width="3" height="89" fill={themeColors.primary}
      />
      <motion.rect
        initial='hidden'
        variants={pathVariants}
        animate={controls}
        custom={2}
        style={{ originX: '50%', originY: 1 }}
        x="238" width="3" height="89" fill={themeColors.primary}
      />
      <motion.rect
        initial='hidden'
        variants={pathVariants}
        animate={controls}
        custom={0}
        style={{ originX: '50%', originY: 0 }}
        x="245" width="23" height="89" fill={themeColors.primary}
      />
      <motion.rect
        initial='hidden'
        variants={pathVariants}
        animate={controls}
        custom={5}
        style={{ originX: '50%', originY: 1 }}
        x="272" width="9" height="89" fill={themeColors.primary}
      />
      <motion.rect
        initial='hidden'
        variants={pathVariants}
        animate={controls}
        custom={4}
        style={{ originX: '50%', originY: 0 }}
        x="338" width="3" height="89" fill={themeColors.primary}
      />
      <motion.rect
        initial='hidden'
        variants={pathVariants}
        animate={controls}
        custom={1}
        style={{ originX: '50%', originY: 0 }}
        x="328" width="6" height="89" fill={themeColors.primary}
      />
      <motion.rect
        initial='hidden'
        variants={pathVariants}
        animate={controls}
        custom={2}
        style={{ originX: '50%', originY: 1 }}
        x="362" width="23" height="89" fill={themeColors.primary}
      />
      <motion.rect
        initial='hidden'
        variants={pathVariants}
        animate={controls}
        custom={0}
        style={{ originX: '50%', originY: 0 }}
        x="396" width="6" height="89" fill={themeColors.primary}
      />
      <motion.rect
        initial='hidden'
        variants={pathVariants}
        animate={controls}
        custom={3}
        style={{ originX: '50%', originY: 1 }}
        x="427" width="3" height="89" fill={themeColors.primary}
      />
      <motion.rect
        initial='hidden'
        variants={pathVariants}
        animate={controls}
        custom={1}
        style={{ originX: '50%', originY: 0 }}
        x="434" width="35" height="89" fill={themeColors.primary}
      />
      <motion.rect
        initial='hidden'
        variants={pathVariants}
        animate={controls}
        custom={4}
        style={{ originX: '50%', originY: 0 }}
        x="473" width="3" height="89" fill={themeColors.primary}
      />
      <motion.rect
        initial='hidden'
        variants={pathVariants}
        animate={controls}
        custom={0}
        style={{ originX: '50%', originY: 0 }}
        x="480" width="9" height="89" fill={themeColors.primary}
      />
    </svg>
  );
};