import * as React from 'react';
import { useContext, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import { ColorThemeContext } from '@providers/ColorThemeContext';

type ServicesIllustrationProps = {
  className?: string
};

export const ServicesIllustration: React.FC<ServicesIllustrationProps> = ({
  className,
}) => {
  const controlsOuter = useAnimation();
  const controls = useAnimation();
  const { ref, inView } = useInView({
    threshold: .5,
    triggerOnce: true,
  });
  const { themeColors } = useContext(ColorThemeContext);

  useEffect(() => {
    if (inView) {
      controlsOuter.start({
        opacity: [0, 1, 1],
        scale: 1,
      });
      controls.start({
        x: 0,
      });
    }
  }, [controlsOuter, controls, inView]);

  return (
    <svg
      className={className}
      ref={ref} width="596" height="57" viewBox="0 0 596 57" fill="none" xmlns="http://www.w3.org/2000/svg"
    >
      <motion.rect
        initial={{
          opacity: 0,
          scale: .7,
        }}
        animate={controlsOuter}
        transition={{
          duration: 1.6,
          ease: [0.6, 0.01, -0.05, 0.95],
        }}
        x="1.5" y="1.5" width="593" height="54" rx="16.5" stroke={themeColors.primary} strokeWidth="3"/>
      <mask id="Services-mask0" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="596" height="57">
        <rect
          width="596" height="57" rx="18" fill="#C4C4C4"
        />
      </mask>
      <g mask="url(#Services-mask0)">
        <motion.g
          initial={{
            x: -500,
          }}
          animate={controls}
          transition={{
            duration: 1.6,
            delay: 1,
            ease: [0.6, 0.01, -0.05, 0.95],
          }}
        >
          <line x1="5.62093" y1="-403.25" x2="-294.379" y2="116.365" stroke={themeColors.primary} strokeWidth="7"/>
          <line x1="17.7459" y1="-396.25" x2="-282.254" y2="123.365" stroke={themeColors.primary} strokeWidth="7"/>
          <line x1="29.8709" y1="-389.25" x2="-270.129" y2="130.365" stroke={themeColors.primary} strokeWidth="7"/>
          <line x1="41.9959" y1="-382.25" x2="-258.004" y2="137.365" stroke={themeColors.primary} strokeWidth="7"/>
          <line x1="54.1209" y1="-375.25" x2="-245.879" y2="144.365" stroke={themeColors.primary} strokeWidth="7"/>
          <line x1="66.242" y1="-368.25" x2="-233.758" y2="151.365" stroke={themeColors.primary} strokeWidth="7"/>
          <line x1="78.367" y1="-361.25" x2="-221.633" y2="158.365" stroke={themeColors.primary} strokeWidth="7"/>
          <line x1="90.492" y1="-354.25" x2="-209.508" y2="165.365" stroke={themeColors.primary} strokeWidth="7"/>
          <line x1="102.617" y1="-347.25" x2="-197.383" y2="172.365" stroke={themeColors.primary} strokeWidth="7"/>
          <line x1="114.742" y1="-340.25" x2="-185.258" y2="179.365" stroke={themeColors.primary} strokeWidth="7"/>
          <line x1="126.867" y1="-333.25" x2="-173.133" y2="186.365" stroke={themeColors.primary} strokeWidth="7"/>
          <line x1="138.988" y1="-326.25" x2="-161.012" y2="193.365" stroke={themeColors.primary} strokeWidth="7"/>
          <line x1="151.113" y1="-319.25" x2="-148.887" y2="200.365" stroke={themeColors.primary} strokeWidth="7"/>
          <line x1="163.238" y1="-312.25" x2="-136.762" y2="207.365" stroke={themeColors.primary} strokeWidth="7"/>
          <line x1="175.363" y1="-305.25" x2="-124.637" y2="214.365" stroke={themeColors.primary} strokeWidth="7"/>
          <line x1="187.488" y1="-298.25" x2="-112.512" y2="221.365" stroke={themeColors.primary} strokeWidth="7"/>
          <line x1="199.613" y1="-291.25" x2="-100.387" y2="228.365" stroke={themeColors.primary} strokeWidth="7"/>
          <line x1="211.734" y1="-284.25" x2="-88.2658" y2="235.365" stroke={themeColors.primary} strokeWidth="7"/>
          <line x1="223.859" y1="-277.25" x2="-76.1408" y2="242.365" stroke={themeColors.primary} strokeWidth="7"/>
          <line x1="235.984" y1="-270.25" x2="-64.0158" y2="249.365" stroke={themeColors.primary} strokeWidth="7"/>
          <line x1="248.109" y1="-263.25" x2="-51.8908" y2="256.365" stroke={themeColors.primary} strokeWidth="7"/>
          <line x1="260.234" y1="-256.25" x2="-39.7658" y2="263.365" stroke={themeColors.primary} strokeWidth="7"/>
          <line x1="272.359" y1="-249.25" x2="-27.6408" y2="270.365" stroke={themeColors.primary} strokeWidth="7"/>
          <line x1="284.48" y1="-242.25" x2="-15.5197" y2="277.365" stroke={themeColors.primary} strokeWidth="7"/>
          <line x1="296.605" y1="-235.25" x2="-3.39467" y2="284.365" stroke={themeColors.primary} strokeWidth="7"/>
          <line x1="308.73" y1="-228.25" x2="8.73033" y2="291.365" stroke={themeColors.primary} strokeWidth="7"/>
          <line x1="320.855" y1="-221.25" x2="20.8553" y2="298.365" stroke={themeColors.primary} strokeWidth="7"/>
          <line x1="332.98" y1="-214.25" x2="32.9803" y2="305.365" stroke={themeColors.primary} strokeWidth="7"/>
          <line x1="345.105" y1="-207.25" x2="45.1053" y2="312.365" stroke={themeColors.primary} strokeWidth="7"/>
          <line x1="357.226" y1="-200.25" x2="57.2264" y2="319.365" stroke={themeColors.primary} strokeWidth="7"/>
          <line x1="369.351" y1="-193.25" x2="69.3514" y2="326.365" stroke={themeColors.primary} strokeWidth="7"/>
          <line x1="381.476" y1="-186.25" x2="81.4764" y2="333.365" stroke={themeColors.primary} strokeWidth="7"/>
          <line x1="393.601" y1="-179.25" x2="93.6014" y2="340.365" stroke={themeColors.primary} strokeWidth="7"/>
          <line x1="405.726" y1="-172.25" x2="105.726" y2="347.365" stroke={themeColors.primary} strokeWidth="7"/>
          <line x1="417.851" y1="-165.25" x2="117.851" y2="354.365" stroke={themeColors.primary} strokeWidth="7"/>
          <line x1="429.972" y1="-158.25" x2="129.973" y2="361.365" stroke={themeColors.primary} strokeWidth="7"/>
          <line x1="442.097" y1="-151.25" x2="142.098" y2="368.365" stroke={themeColors.primary} strokeWidth="7"/>
          <line x1="454.222" y1="-144.25" x2="154.223" y2="375.365" stroke={themeColors.primary} strokeWidth="7"/>
          <line x1="466.347" y1="-137.25" x2="166.348" y2="382.365" stroke={themeColors.primary} strokeWidth="7"/>
          <line x1="478.472" y1="-130.25" x2="178.473" y2="389.365" stroke={themeColors.primary} strokeWidth="7"/>
          <line x1="490.597" y1="-123.25" x2="190.598" y2="396.365" stroke={themeColors.primary} strokeWidth="7"/>
          <line x1="502.719" y1="-116.25" x2="202.719" y2="403.365" stroke={themeColors.primary} strokeWidth="7"/>
          <line x1="514.844" y1="-109.25" x2="214.844" y2="410.365" stroke={themeColors.primary} strokeWidth="7"/>
        </motion.g>
      </g>
    </svg>
  );
};