// extracted by mini-css-extract-plugin
export var root = "Problems-module--root--JNfRD";
export var container = "Problems-module--container--1ECo5";
export var heading = "Problems-module--heading--3SVWQ";
export var headingLine = "Problems-module--headingLine--3ZTZ7";
export var boldText = "Problems-module--boldText--SMe-z";
export var question = "Problems-module--question--3k1XE";
export var listItem = "Problems-module--listItem--2uGY4";
export var listArrow = "Problems-module--listArrow--M96_L";
export var button = "Problems-module--button--23JuD";
export var buttonArrow = "Problems-module--buttonArrow--1OkbT";
export var answer = "Problems-module--answer--2RdY_";
export var answerFirst = "Problems-module--answerFirst--vHb40";
export var answerSecond = "Problems-module--answerSecond--1p9Vm";
export var mobile = "Problems-module--mobile--1h5ia";