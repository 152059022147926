// extracted by mini-css-extract-plugin
export var container = "FirstScreen-module--container--3332M";
export var cat = "FirstScreen-module--cat--2ObQc";
export var clew = "FirstScreen-module--clew--3UeNW";
export var description = "FirstScreen-module--description--FbikZ";
export var mainBlock = "FirstScreen-module--mainBlock--qKI2G";
export var firstLine = "FirstScreen-module--firstLine--1EX0Z";
export var secondLine = "FirstScreen-module--secondLine--SZtNT";
export var finalLineWrapper = "FirstScreen-module--finalLineWrapper--2qjrA";
export var finalLineInner = "FirstScreen-module--finalLineInner--DDeRb";
export var marquee = "FirstScreen-module--marquee--3oiGl";
export var finalLineInnerAnimate = "FirstScreen-module--finalLineInnerAnimate--TF_ui";
export var finalLineLetters = "FirstScreen-module--finalLineLetters--2Opln";
export var button = "FirstScreen-module--button--1XrrV";
export var buttonArrow = "FirstScreen-module--buttonArrow--xshDs";
export var links = "FirstScreen-module--links--WlI1m";
export var title = "FirstScreen-module--title--hg46S";
export var letter = "FirstScreen-module--letter--Htybi";
export var dashMobile = "FirstScreen-module--dashMobile--3AtS2";
export var mobile = "FirstScreen-module--mobile--2-5Ep";
export var marqueeLtablet = "FirstScreen-module--marqueeLtablet--2Jahx";
export var dashDesktop = "FirstScreen-module--dashDesktop--2BpYq";
export var marqueeLphone = "FirstScreen-module--marqueeLphone--3E2uI";
export var marqueeSphone = "FirstScreen-module--marqueeSphone--2zhWq";