import * as React from 'react';
import { forwardRef, useContext } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import cx from 'classnames';

import { CursorContext, CursorTypes } from '@providers/CursorProvider';
import { ColorModes, ColorThemeContext } from '@providers/ColorThemeContext';
import { Heading } from '@components/common/Heading';
import { Container } from '@components/common/Container';
import { AnimatedLine } from '@components/common/AnimatedLine';
import { LinkArrow } from '@components/common/LinkArrow';
import { Experience } from '@components/home/About/Experience';

import { AboutIllustration } from './AboutIllustration';
import * as s from './About.module.sass';

type AboutProps = {
  scrollToContactSection: () => void
};

const modeClass = {
  [ColorModes.Light]: s.light,
  [ColorModes.Dark]: s.dark,
};

const getAge = (dateString: string) => {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const About = forwardRef<HTMLElement, AboutProps>(
  ({ scrollToContactSection }, ref) => {
    const { toggleCursorType } = useContext(CursorContext);
    const { colorThemeMode } = useContext(ColorThemeContext);

    return (
      <section className={cx(s.root, modeClass[colorThemeMode])} ref={ref}>
        <AnimatedLine direction='horizontal' />
        <Heading
          title='About me'
          illustration={<AboutIllustration className={s.headingIllustration} />}
        />
        <Container>
          <AnimatedLine delay={1.2} />
          <div className={s.general}>
            <div className={s.generalInfo}>
              <div className={s.generalContent}>
                <p>Hello! My name is Ilona K and I am thrilled to meet you!</p>
                <p>I am a first-generation immigrant from Ukraine, currently living in Philadelphia, PA.</p>
              </div>
              <img
                src='/worldMap.svg'
                className={cx(s.worldMap, s.lightImage)}
                alt="I am a first-generation immigrant from Ukraine, currently living in Philadelphia, PA"
                width='600'
                height='300'
              />
              <img
                src='/worldMapDark.svg'
                className={cx(s.worldMap, s.darkImage)}
                alt="I am a first-generation immigrant from Ukraine, currently living in Philadelphia, PA"
                width='600'
                height='300'
              />
            </div>
            <StaticImage
              src="../../../images/Ilona_Kravchenko.jpg"
              alt="Ilona Kravchenko"
              placeholder="blurred"
              className={s.generalImage}
            />
            <StaticImage
              src="../../../images/Ilona_Kravchenko_Dark.jpg"
              alt="Ilona Kravchenko - B&W"
              placeholder="blurred"
              className={cx(s.generalImage, s.generalImageDark)}
            />
          </div>
          <div className={s.experience}>
            <h3 className={s.experienceHeader}>
              In my {getAge('12-03-1996')} years of age, I have worked&nbsp;as&nbsp;a:
            </h3>
            <Experience />
          </div>
          <div className={s.final}>
            <p className={s.finalFirst}>
              And only looking back do I realize that all of these experiences played a major role in forming my skill set and extensive knowledge in so many different areas of expertise. Every experience has made me different from the person I was before and has trained me to find unique solutions for every task that I am given.
            </p>
            <p className={s.finalSecond}>
              My goal is to contribute in some way to make a long-lasting difference.
            </p>
            <p className={s.finalThird}>
              Perhaps, in one person&apos;s life.
              <br />
              Perhaps, in many.
            </p>
            <p className={s.finalFourth}>
              For this very reason, I turned my passion into a powerful tool that I use to help businesses and brands on their way to success while building genuine connections with their audience.
            </p>
            <button
              className={s.finalButton}
              type='button'
              onClick={scrollToContactSection}
              onMouseEnter={() => toggleCursorType(CursorTypes.pointer)}
              onMouseLeave={() => toggleCursorType(CursorTypes.default)}
            >
              I found my voice. And so can you.
              <LinkArrow className={s.buttonArrow} />
            </button>
          </div>
          <AnimatedLine delay={1.4} align='right' className={s.mobile} />
        </Container>
      </section>
    );
  },
);