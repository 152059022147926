import * as React from 'react';
import { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const pathVariants = {
  hidden: (i: number) => ({
    scale: 6,
    y: -(i + 1) * 10,
    opacity: 0,
  }),
  visible: (i: number) => ({
    y: 0,
    scale: [.6, 1.1, 1],
    opacity: 1,
    transition: {
      duration: .5,
      delay: .3 * (5 - i),
      ease: [0.6, 0.01, -0.05, 0.95],
    },
  }),
};

type QuestionSecondQuestionIllustrationProps = {
  className?: string
};

export const QuestionSecondQuestionIllustration: React.FC<QuestionSecondQuestionIllustrationProps> = ({
  className,
}) => {
  const controls = useAnimation();
  const { ref, inView } = useInView({
    threshold: .5,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <svg
      ref={ref}
      className={className}
      width="91" height="270" viewBox="0 0 91 270" fill="none" xmlns="http://www.w3.org/2000/svg">
      <motion.path
        initial='hidden'
        animate={controls}
        variants={pathVariants}
        custom={0}
        d="M49.4991 270L23.9514 225.75L75.0469 225.75L49.4991 270Z" fill="#1F1F1F" fillOpacity="0.8"
      />
      <motion.path
        initial='hidden'
        animate={controls}
        variants={pathVariants}
        custom={1}
        d="M71.9294 183.069L53.683 151.465L90.1758 151.465L71.9294 183.069Z" fill="#1F1F1F" fillOpacity="0.6"
      />
      <motion.path
        initial='hidden'
        animate={controls}
        variants={pathVariants}
        custom={2}
        d="M15.7003 148.073L0.0178776 120.91L31.3828 120.91L15.7003 148.073Z" fill="#1F1F1F" fillOpacity="0.4"
      />
      <motion.path
        initial='hidden'
        animate={controls}
        variants={pathVariants}
        custom={3}
        d="M72.221 96.5581L60.8169 76.8057L83.625 76.8057L72.221 96.5581Z" fill="#1F1F1F" fillOpacity="0.2"
      />
      <motion.path
        initial='hidden'
        animate={controls}
        variants={pathVariants}
        custom={4}
        d="M37.4026 65.4084L25.856 45.4091L48.9492 45.4091L37.4026 65.4084Z" fill="#1F1F1F" fillOpacity="0.1"
      />
      <motion.path
        initial='hidden'
        animate={controls}
        variants={pathVariants}
        custom={5}
        d="M71.6483 14.9004L63.5427 0.861065L79.7539 0.861066L71.6483 14.9004Z" fill="#1F1F1F" fillOpacity="0.05"
      />
    </svg>
  );
};