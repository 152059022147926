import * as React from 'react';

export const ServicesContent = [
  {
    id: 1,
    title: 'Web-site copywriting',
    content: (
      <>
        <p>
          The purpose behind every good copy is simple - you want to help your ideal customer make the right choice. You want to inspire them to take action.
        </p>
        <p>
          It may sound cliché but words ARE truly powerful.
        </p>
        <p>
          Most of our decisions as consumers are driven by emotion, and having a high-quality copy that resonates with your ideal prospect is crucial for building trust + getting desirable results.
        </p>
        <p>
          It can significantly transform your business growth and help you convert your website visitors into prospective leads, and your leads into lucky first time buyers! Remember, your idea is unique. And so should be your copy!
        </p>
      </>
    ),
  },
  {
    id: 2,
    title: 'Branding strategy',
    content: (
      <>
        <p>
          If you are wondering how you can turn browsers into buyers, the answer is not that difficult: connect with them.
        </p>
        <p>
          To build that strong connection and develop customer loyalty, you have to KNOW where your business stands first. How do you create value? What is your brand&apos;s personality? How would you describe its story? And most importantly, why should people care?
        </p>
        <p>
          Defining your strategy is critical. Think of it as a solid foundation for your successful business journey.
        </p>
        <p>
          Well-defined and executed branding strategy is essential. It serves as a framework, bringing structure, clarity, and... real results!
        </p>
      </>
    ),
  },
  {
    id: 3,
    title: 'Monthly content creation',
    content: (
      <>
        <p>
          If you have already established your brand’s identity and your website copy produces ROI, it is important to stay consistent and be present in front of your audience by utilizing other communication channels. If you want to gain new prospects and engage your loyal audience, you have to create more value. We now have YouTube, short clips, podcasts, visuals, and many more possibilities that you can utilize on a daily basis. So why waste your message, when you can make so much more out of it?
        </p>
        <p>
          I can repurpose the content that you have already created from any means of communication and provide more text value for other channels. This includes blog posts and social media copy.
        </p>
        <p>
          You can also inquire about my monthly content development packages that include writing from the scratch by contacting me directly, using the form below!
        </p>
      </>
    ),
  },
];