import * as React from 'react';
import { RefObject, useEffect, useRef, useState } from 'react';
import { PageProps } from 'gatsby';

import { BaseLayout } from '@layouts/BaseLayout';
import { NavLinksSections } from '@components/common/Header';
import { FirstScreen } from '@components/home/FirstScreen';
import { QuestionFirstBlock } from '@components/home/QuestionFirstBlock';
import { QuestionSecondBlock } from '@components/home/QuestionSecondBlock';
import { About } from '@components/home/About';
import { MyProject } from '@components/home/MyProject';
import { Problems } from '@components/home/Problems';
import { Services } from '@components/home/Services';
import { Contact } from '@components/home/Contact';

const IndexPage: React.FC<PageProps> = () => {
  const firstScreenSectionRef = useRef<HTMLElement>(null);
  const howCanIHelpSectionRef = useRef<HTMLElement>(null);
  const aboutSectionRef = useRef<HTMLElement>(null);
  const projectSectionRef = useRef<HTMLDivElement>(null);
  const servicesSectionRef = useRef<HTMLElement>(null);
  const contactSectionRef = useRef<HTMLElement>(null);

  const [fixedHeader, setFixedHeader] = useState(false);

  const scrollToSection = (ref: RefObject<HTMLElement> | null) => {
    const element = ref?.current;
    if (element) {
      let customOffset = -86;
      if (window.innerWidth <= 1366) {
        customOffset = -78;
      }
      if (window.innerWidth <= 768) {
        customOffset = -70;
      }
      if (window.innerWidth <= 620) {
        customOffset = -62;
      }
      if (window.innerWidth <= 425) {
        customOffset = -54;
      }
      if (window.innerWidth > 1440) {
        customOffset = -94;
      }
      if (window.innerWidth > 1920) {
        customOffset = -102;
      }
      if (window.innerWidth > 2200) {
        customOffset = -110;
      }
      if (window.innerWidth > 2400) {
        customOffset = -118;
      }
      const y = element.getBoundingClientRect().top + window.pageYOffset + customOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  const scrollFromNavigation = (section: NavLinksSections) => {
    switch (section) {
      case NavLinksSections.FirstScreenSection:
        scrollToSection(firstScreenSectionRef);
        break;
      case NavLinksSections.HowCanIHelpSection:
        scrollToSection(howCanIHelpSectionRef);
        break;
      case NavLinksSections.AboutSection:
        scrollToSection(aboutSectionRef);
        break;
      case NavLinksSections.ProjectSection:
        scrollToSection(projectSectionRef);
        break;
      case NavLinksSections.ServicesSection:
        scrollToSection(servicesSectionRef);
        break;
      default:
        scrollToSection(contactSectionRef);
    }
  };

  const resizeHeaderOnScroll = () => {
    const element = firstScreenSectionRef?.current;
    if (element) {
      const distanceY = window.pageYOffset || document.documentElement.scrollTop;
      const shrinkOn = element.offsetHeight;
      if (distanceY > shrinkOn) {
        setFixedHeader(true);
      } else {
        setFixedHeader(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', resizeHeaderOnScroll);
    return () => window.removeEventListener('scroll', resizeHeaderOnScroll);
  }, []);

  return (
    <BaseLayout
      scrollToSection={(section) => scrollFromNavigation(section)}
      isFixedHeader={fixedHeader}
    >
      <FirstScreen
        scrollToContactSection={() => scrollToSection(contactSectionRef)}
        ref={firstScreenSectionRef}
      />
      <QuestionFirstBlock ref={howCanIHelpSectionRef} />
      <QuestionSecondBlock
        scrollToAboutSection={() => scrollToSection(aboutSectionRef)}
      />
      <About
        scrollToContactSection={() => scrollToSection(contactSectionRef)}
        ref={aboutSectionRef}
      />
      <MyProject
        ref={projectSectionRef}
      />
      <Problems
        scrollToContactSection={() => scrollToSection(contactSectionRef)}
      />
      <Services ref={servicesSectionRef} />
      <Contact ref={contactSectionRef} />
    </BaseLayout>
  );
};

export default IndexPage;
