import * as React from 'react';
import { ReactElement, useContext, useEffect } from 'react';
import cx from 'classnames';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import { ColorModes, ColorThemeContext } from '@providers/ColorThemeContext';
import { Container } from '@components/common/Container';
import { AnimatedLine } from '@components/common/AnimatedLine';


const pathVariants = {
  hidden: {
    width: 0,
  },
  visible: (i: number) => ({
    width: '100%',
    transition: {
      duration: .6,
      delay: .4 * (i + 1),
      ease: [0.6, 0.01, -0.05, 0.95],
    },
  }),
};

import * as s from './Heading.module.sass';

type HeadingProps = {
  title: string
  className?: string
  illustration?: ReactElement<{ className?: string }>
};

const modeClass = {
  [ColorModes.Light]: s.light,
  [ColorModes.Dark]: s.dark,
};


export const Heading: React.FC<HeadingProps> = ({
  title,
  className,
  illustration,
}) => {
  const controls = useAnimation();
  const { ref, inView } = useInView({
    threshold: .2,
    triggerOnce: true,
  });
  const { colorThemeMode } = useContext(ColorThemeContext);

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  let content;
  const titleMap = title.split(' ');
  content = titleMap.map((el, i) => (
    <React.Fragment key={`${title}-${el}`}>
      <span
        className={s.headerWord}
      >
        {el}
        <motion.span
          className={s.headerWordAnimation}
          custom={i}
          initial='hidden'
          animate={controls}
          variants={pathVariants}
        >
          {el}
        </motion.span>
      </span>
      {i !== titleMap.length - 1 ? ' ' : ''}
    </React.Fragment>
  ));

  return (
    <Container className={cx(s.root, className)}>
      <AnimatedLine delay={.4} duration={.6} />
      <h2 className={cx(s.header, modeClass[colorThemeMode])} ref={ref}>
        {content}
      </h2>
      {illustration}
      <AnimatedLine direction='horizontal' verticalAlign='bottom' delay={.9} />
      <AnimatedLine duration={.4} delay={1.2} align='right' className={s.mobile} />
    </Container>
  );
};