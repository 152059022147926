import * as React from 'react';
import { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

type ContactIllustrationProps = {
  className?: string
};

export const ContactIllustration: React.FC<ContactIllustrationProps> = ({
  className,
}) => {
  const controls = useAnimation();
  const { ref, inView } = useInView({
    threshold: .5,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      controls.start({
        rotate: [-30, 5, 0],
        opacity: 1,
        scale: 1,
      });
    }
  }, [controls, inView]);

  return (
    <motion.svg
      ref={ref}
      className={className}
      initial={{
        scale: 0.3,
        rotate: -30,
        opacity: 0,
      }}
      transition={{
        duration: 1.2,
        ease: [0.6, 0.01, -0.05, 0.95],
      }}
      animate={controls}
      width="330" height="114" viewBox="0 0 330 114" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M218.75 0L220.871 2.12131L313.751 95.0012H326.629V56.0012H329.629V95.0012V110.001V113.001H326.629H311.629H272.629V110.001H311.629V97.1213L218.75 4.24252L166 56.9926V57.0012H163V18.0012H149.522L58.5403 108.983L58.4893 109.012L54.5 113.001L54.4974 113.005L52.4952 111.002L52.489 111L0.613281 59.1248L2.73459 57.0034L43.8887 98.1575L53 89.0461V0.0012207H56V89.0512L65.1146 98.1659L148 15.2805V3.00122H109V0.0012207H148H163H166V3.00122V18.0012V52.75L216.629 2.12134L218.75 0Z" fill="#1F1F1F"/>
    </motion.svg>
  );
};