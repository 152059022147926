import * as React from 'react';
import { useContext, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import cx from 'classnames';

import { ColorModes, ColorThemeContext } from '@providers/ColorThemeContext';

const pathVariants = {
  hidden: {
    width: 0,
  },
  visible: (i: number) => ({
    width: '100%',
    transition: {
      duration: .6,
      delay: .4 * ((i >= 4 ? i / 4 : i) + 1),
      ease: [0.6, 0.01, -0.05, 0.95],
    },
  }),
};

import * as s from './HeadingThird.module.sass';

type HeadingThirdProps = {
  lineFirst: string
  lineSecond?: string
  lineSecondClassName?: string
  className?: string
};

const modeClass = {
  [ColorModes.Light]: s.light,
  [ColorModes.Dark]: s.dark,
};

export const HeadingThird: React.FC<HeadingThirdProps> = ({
  lineFirst,
  lineSecond,
  lineSecondClassName,
  className,
}) => {
  const controls = useAnimation();
  const { ref, inView } = useInView({
    threshold: .2,
    triggerOnce: true,
  });
  const { colorThemeMode } = useContext(ColorThemeContext);

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  let content = [];
  let contentFirst = [];
  const titleMap = lineFirst.split(' ');
  contentFirst.push(titleMap.map((el, i) => (
    <React.Fragment
      key={`1-${el}-${i}`}
    >
      <span
        className={s.headerWord}
      >
        {el}
        <motion.span
          className={s.headerWordAnimation}
          custom={i}
          initial='hidden'
          animate={controls}
          variants={pathVariants}
        >
          {el}
        </motion.span>
      </span>
      {i !== titleMap.length - 1 ? ' ' : ''}
    </React.Fragment>
  )));
  if (lineSecondClassName) {
    content.push(
      <span key='contentFirst'>
        {contentFirst}
      </span>,
    );
  } else {
    content.push(contentFirst);
  }

  if (lineSecond) {
    content.push(
      <React.Fragment
        key='space-br'
      >
        {' '}
        {!lineSecondClassName && <br/>}
      </React.Fragment>,
    );
    let contentSecond = [];
    const titleMap2 = lineSecond.split(' ');
    contentSecond.push(titleMap2.map((el, i) => (
      <React.Fragment
        key={`2-${el}-${i}`}
      >
        <span
          className={s.headerWord}
        >
          {el}
          <motion.span
            className={s.headerWordAnimation}
            custom={i}
            initial='hidden'
            animate={controls}
            variants={pathVariants}
          >
            {el}
          </motion.span>
        </span>
        {i !== titleMap2.length - 1 ? ' ' : ''}
      </React.Fragment>
    )));
    if (lineSecondClassName) {
      content.push(
        <span key='contentSecond' className={lineSecondClassName}>
          {contentSecond}
        </span>,
      );
    } else {
      content.push(contentSecond);
    }
  }

  return (
    <h3 className={cx(modeClass[colorThemeMode], className)} ref={ref}>
      {content}
    </h3>
  );
};