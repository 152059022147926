// extracted by mini-css-extract-plugin
export var root = "Contact-module--root--3uuxr";
export var headingIllustration = "Contact-module--headingIllustration--10HYr";
export var content = "Contact-module--content--3IUWD";
export var info = "Contact-module--info--2Dnlz";
export var links = "Contact-module--links--1benA";
export var form = "Contact-module--form--2oRAy";
export var input = "Contact-module--input--1MF_e";
export var inputs = "Contact-module--inputs--3m4Dj";
export var buttonWrapper = "Contact-module--buttonWrapper--3YDex";
export var privacyBlock = "Contact-module--privacyBlock--j7LlA";
export var arrowLink = "Contact-module--arrowLink--16e2L";
export var arrowWrapper = "Contact-module--arrowWrapper--O0Uay";
export var privacy = "Contact-module--privacy--1o1I_";
export var blur = "Contact-module--blur--1v043";
export var blurActive = "Contact-module--blurActive--2mdhE";
export var cat = "Contact-module--cat--suizc";
export var linksMobile = "Contact-module--linksMobile--1P5V2";
export var mobile = "Contact-module--mobile--3Sbpl";
export var linksDesktop = "Contact-module--linksDesktop--1w8u3";
export var noTablet = "Contact-module--noTablet--ly2mK";
export var socialLink = "Contact-module--socialLink--2ocvb";
export var light = "Contact-module--light--3gWqn";
export var dark = "Contact-module--dark--3zUkw";