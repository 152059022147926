import * as React from 'react';
import { useContext } from 'react';
import cx from 'classnames';

import { CursorContext, CursorTypes } from '@providers/CursorProvider';
import { LinkArrow } from '@components/common/LinkArrow';
import LinkArrowStatic from '@icons/LinkArrow.inline.svg';

import * as s from './SocialLink.module.sass';

type ContactLinkProps = {
  delay?: number
  direction?: 'vertical' | 'horizontal'
  size?: 'small'
  title: string
  href: string
  className?: string
};

export const SocialLink: React.FC<ContactLinkProps> = ({
  delay,
  direction,
  size,
  title,
  href,
  className,
}) => {
  const { toggleCursorType } = useContext(CursorContext);
  
  return (
    <a
      href={href}
      target='_blank'
      rel="noreferrer noopener"
      className={cx(s.root, { [s.small]: size === 'small' }, className)}
      onMouseEnter={() => toggleCursorType(CursorTypes.pointer)}
      onMouseLeave={() => toggleCursorType(CursorTypes.default)}
    >
      {title}
      {
        direction === 'vertical'
          ? <LinkArrow
            delay={delay}
            className={s.arrow}
          />
          : <LinkArrowStatic className={s.arrow}/>
      }
    </a>
  );
};