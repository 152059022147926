import * as React from 'react';
import { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const pathVariants = {
  hidden: (i: number) => ({
    scale: .4,
    rotate: i % 2 === 0 ? 20 : -20,
    opacity: 0,
  }),
  visible: (i: number) => ({
    rotate: 0,
    scale: [.4, 1.05, 1],
    opacity: [0, 1, 1],
    transition: {
      duration: 1.2,
      delay: .3 * i,
      ease: [0.6, 0.01, -0.05, 0.95],
    },
  }),
};

type AboutIllustrationProps = {
  className?: string
};

export const AboutIllustration: React.FC<AboutIllustrationProps> = ({
  className,
}) => {
  const controls = useAnimation();
  const { ref, inView } = useInView({
    threshold: .7,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <svg
      className={className}
      ref={ref}
      width="491" height="184" viewBox="0 0 491 184" fill="none" xmlns="http://www.w3.org/2000/svg">

      <motion.g
        initial='hidden'
        animate={controls}
        variants={pathVariants}
        custom={0}
      >
        <path d="M444.462 111.26C442.863 113.953 443.237 117.645 445.458 121.096C447.386 124.092 449.905 125.891 452.664 126.252C454.574 126.5 456.535 125.735 457.38 124.418C457.693 123.924 458.096 122.741 458.226 121.939C458.568 119.758 458.063 117.334 456.814 115.156C456.13 113.962 455.557 113.241 454.453 112.17C452.957 110.718 452.077 110.126 450.706 109.662C449.352 109.203 448.067 109.142 446.916 109.479C445.85 109.795 444.94 110.457 444.462 111.26Z" fill="#1F1F1F"/>
        <path d="M431.893 123.676C430.876 125.097 430.825 127.417 431.771 129.445C432.555 131.133 434.518 133.209 436.435 134.388C439.637 136.347 442.98 136.765 445.762 135.551C446.366 135.292 447.018 134.723 447.396 134.134C448.57 132.286 448.35 129.775 446.784 127.293C446.292 126.504 444.926 125.043 444.057 124.376C440.65 121.75 436.863 120.94 433.849 122.188C433.359 122.39 432.396 122.987 432.243 123.188C432.222 123.209 432.068 123.432 431.893 123.676Z" fill="#1F1F1F"/>
        <path d="M463.05 113.542C460.926 116.239 460.971 120.451 463.161 124.196C463.784 125.25 464.673 126.372 465.355 126.955C466.641 128.05 468.068 128.671 469.455 128.729C471.12 128.801 472.253 128.271 473.194 126.977C475.114 124.331 474.925 120.068 472.737 116.511C472.23 115.684 471.298 114.54 470.674 113.984C469.045 112.525 466.816 111.839 465.095 112.275C464.304 112.472 463.493 112.975 463.05 113.542Z" fill="#1F1F1F"/>
        <path d="M434.98 142.34C434.257 143.412 434.026 144.904 434.383 146.109C434.848 147.686 436.353 149.551 438.151 150.771C439.451 151.653 441.342 152.403 442.828 152.639C445.902 153.118 449.1 152.135 450.257 150.346C450.64 149.751 450.817 149.063 450.831 148.109C450.861 146.411 450.253 145.121 448.659 143.459C446.97 141.709 444.796 140.579 442.278 140.152C441.343 139.994 439.692 139.991 438.833 140.15C436.915 140.493 435.78 141.141 434.98 142.34Z" fill="#1F1F1F"/>
        <path d="M455.98 135.409C454.435 137.375 453.488 139.815 453.201 142.555C453.077 143.742 453.131 144.788 453.406 146.485C454.039 150.443 455.098 153.491 456.937 156.647C457.57 157.744 457.732 157.977 458.239 158.498C458.704 158.977 458.921 159.156 459.341 159.382C460.978 160.274 462.686 160.357 464.154 159.618C464.618 159.389 464.819 159.236 465.379 158.692C466.526 157.578 467.031 156.771 468.452 153.753C469.752 151.013 470.065 150.476 471.145 149.173C472.585 147.437 473.916 146.609 477.483 145.235C480.452 144.083 481.058 143.749 481.967 142.749C482.92 141.708 483.358 140.69 483.382 139.464C483.412 138.114 482.95 137.159 481.723 136.005C479.958 134.351 477.162 133.223 472.157 132.149C468.119 131.276 465.078 131.114 462.332 131.627C460.849 131.905 459.728 132.329 458.566 133.046C457.992 133.402 456.445 134.81 455.98 135.409Z" fill="#1F1F1F"/>
      </motion.g>

      <motion.g
        initial='hidden'
        animate={controls}
        variants={pathVariants}
        custom={1}
      >
        <path d="M371.612 136.129C371.517 137.529 371.732 138.606 372.347 139.891C373.392 142.082 375.3 143.891 377.993 145.243C379.972 146.232 381.86 146.624 383.78 146.439C384.721 146.352 385.225 146.228 386.003 145.881C387.414 145.254 388.347 144.343 388.777 143.152C389.017 142.49 389.058 141.973 388.956 141.098C388.593 138.042 386.346 135.167 382.956 133.403C381.572 132.685 379.395 132.044 378.024 131.959C375.796 131.815 373.667 132.649 372.45 134.143C372.074 134.604 371.641 135.624 371.612 136.129Z" fill="#1F1F1F"/>
        <path d="M365.704 153.243C365.649 155.181 366.834 157.229 368.769 158.54C369.032 158.724 369.716 159.084 370.282 159.345C373.685 160.897 377.682 160.979 380.68 159.548C381.876 158.981 383.084 158 383.711 157.09C384.609 155.787 384.46 153.59 383.368 152.019C382.325 150.514 380.856 149.451 378.762 148.695C377.522 148.247 376.492 148.02 375.237 147.905C371.565 147.569 368.513 148.634 366.64 150.922C366.18 151.481 365.723 152.613 365.704 153.243Z" fill="#1F1F1F"/>
        <path d="M389.24 130.644C388.991 132.423 389.535 134.53 390.667 136.236C391.808 137.937 393.304 139.259 395.295 140.325C396.423 140.926 396.891 141.103 397.833 141.282C399.319 141.561 400.729 141.426 401.881 140.891C402.683 140.521 403.226 140.088 403.657 139.454C404.649 138.024 404.695 135.712 403.774 133.534C403.186 132.129 401.783 130.349 400.42 129.284C399.172 128.302 397.437 127.417 396.144 127.095C394.991 126.808 393.592 126.802 392.543 127.075C392.178 127.169 391.347 127.554 390.96 127.8C390.039 128.386 389.399 129.444 389.24 130.644Z" fill="#1F1F1F"/>
        <path d="M376.773 167.839C376.394 169.757 377.08 171.559 378.603 172.65C379.757 173.469 381.087 174.047 382.499 174.331C384.99 174.836 387.494 174.573 389.635 173.574C391.1 172.886 392.078 172.119 393.016 170.927C394.115 169.512 394.25 167.957 393.42 166.352C392.714 164.985 391.76 164.213 389.791 163.421C388.911 163.071 388.578 162.967 387.798 162.83C385.355 162.39 383.066 162.683 380.859 163.707C379.378 164.397 378.105 165.355 377.506 166.234C377.237 166.621 376.839 167.5 376.773 167.839Z" fill="#1F1F1F"/>
        <path d="M392.576 152.703C391.791 156.215 392.547 160.186 394.603 163.258C396.337 165.859 398.309 168.094 400.429 169.851C401.847 171.032 403.825 172.335 404.672 172.641C406.596 173.336 408.951 172.793 410.253 171.353C410.751 170.8 411.155 170.036 411.364 169.257C411.749 167.857 411.835 166.841 411.794 164.054C411.751 161.074 411.811 160.33 412.192 158.695C412.711 156.457 413.527 155.132 416 152.48C418.184 150.135 418.596 149.575 419.016 148.413C419.801 146.239 419.29 144.088 417.713 142.923C417.139 142.506 415.548 141.915 414.75 141.828C412.735 141.607 410.579 141.755 408.103 142.279C402.547 143.454 399.246 144.719 396.556 146.704C395.359 147.585 394.49 148.52 393.782 149.699C393.41 150.305 392.74 151.981 392.576 152.703Z" fill="#1F1F1F"/>
      </motion.g>

      <motion.g
        initial='hidden'
        animate={controls}
        variants={pathVariants}
        custom={2}
      >
        <path d="M352.659 61.2122C351.657 64.1799 352.792 67.7127 355.684 70.6246C358.193 73.1533 361.032 74.388 363.806 74.1662C365.725 74.01 367.484 72.8533 368.036 71.3895C368.239 70.8413 368.386 69.5996 368.346 68.7883C368.227 66.5835 367.227 64.3182 365.552 62.4489C364.634 61.4237 363.923 60.8381 362.62 60.0202C360.855 58.9115 359.87 58.5167 358.433 58.348C357.013 58.1814 355.744 58.3892 354.687 58.9588C353.711 59.4908 352.959 60.328 352.659 61.2122Z" fill="#1F1F1F"/>
        <path d="M342.954 75.9746C342.255 77.5769 342.689 79.8558 344.037 81.6426C345.156 83.1305 347.508 84.751 349.628 85.5052C353.169 86.7539 356.525 86.4659 358.994 84.6989C359.53 84.3195 360.049 83.6269 360.296 82.9717C361.059 80.9198 360.32 78.51 358.272 76.4088C357.626 75.7393 355.985 74.5951 354.997 74.1246C351.117 72.2658 347.245 72.2628 344.557 74.1114C344.119 74.4111 343.302 75.1956 343.195 75.4245C343.178 75.4497 343.074 75.6996 342.954 75.9746Z" fill="#1F1F1F"/>
        <path d="M371.318 59.5698C369.802 62.65 370.724 66.7603 373.647 69.9665C374.475 70.8675 375.578 71.7796 376.367 72.2077C377.853 73.0112 379.378 73.3208 380.747 73.088C382.391 72.8116 383.388 72.0569 384.039 70.5954C385.365 67.6073 384.291 63.4777 381.411 60.4545C380.742 59.7513 379.592 58.8265 378.866 58.4133C376.969 57.3253 374.645 57.1192 373.053 57.9043C372.321 58.2624 371.632 58.9231 371.318 59.5698Z" fill="#1F1F1F"/>
        <path d="M349.861 93.5859C349.377 94.785 349.462 96.292 350.063 97.3965C350.846 98.8418 352.706 100.352 354.72 101.171C356.175 101.762 358.181 102.102 359.683 102.023C362.789 101.85 365.712 100.223 366.47 98.232C366.722 97.5705 366.751 96.8602 366.566 95.9247C366.241 94.2575 365.378 93.1231 363.473 91.8292C361.456 90.4699 359.094 89.8182 356.543 89.9248C355.595 89.9651 353.98 90.3067 353.173 90.6415C351.368 91.3758 350.393 92.2461 349.861 93.5859Z" fill="#1F1F1F"/>
        <path d="M368.958 82.4303C367.857 84.6749 367.439 87.2591 367.729 89.998C367.855 91.1856 368.126 92.197 368.749 93.7996C370.193 97.5389 371.864 100.299 374.321 103.002C375.168 103.943 375.375 104.137 375.98 104.542C376.534 104.913 376.783 105.043 377.241 105.177C379.028 105.707 380.716 105.433 381.998 104.404C382.404 104.083 382.569 103.892 383.003 103.243C383.893 101.915 384.218 101.02 384.979 97.7725C385.679 94.821 385.874 94.2308 386.659 92.7318C387.705 90.733 388.834 89.6465 392.037 87.5593C394.701 85.8136 395.223 85.3606 395.904 84.1934C396.619 82.9758 396.836 81.8897 396.603 80.685C396.352 79.3585 395.7 78.5208 394.26 77.6481C392.189 76.398 389.219 75.8775 384.1 75.8701C379.969 75.8582 376.961 76.3338 374.382 77.4071C372.99 77.9888 371.982 78.6363 370.994 79.5802C370.508 80.0479 369.288 81.7479 368.958 82.4303Z" fill="#1F1F1F"/>
      </motion.g>

      <motion.g
        initial='hidden'
        animate={controls}
        variants={pathVariants}
        custom={3}
      >
        <path d="M281.931 104.538C281.868 105.939 282.108 107.011 282.752 108.282C283.848 110.448 285.797 112.212 288.521 113.502C290.522 114.444 292.419 114.793 294.333 114.564C295.273 114.455 295.774 114.319 296.543 113.954C297.939 113.295 298.851 112.362 299.254 111.162C299.478 110.494 299.507 109.976 299.384 109.104C298.952 106.057 296.638 103.235 293.208 101.55C291.808 100.864 289.617 100.273 288.244 100.221C286.014 100.128 283.904 101.011 282.723 102.533C282.358 103.002 281.948 104.032 281.931 104.538Z" fill="#1F1F1F"/>
        <path d="M276.418 121.784C276.407 123.723 277.639 125.743 279.604 127.009C279.872 127.187 280.563 127.53 281.136 127.778C284.573 129.251 288.572 129.241 291.536 127.741C292.718 127.146 293.903 126.137 294.509 125.213C295.376 123.889 295.177 121.697 294.049 120.151C292.971 118.671 291.478 117.643 289.366 116.935C288.117 116.515 287.082 116.312 285.824 116.226C282.145 115.976 279.12 117.111 277.299 119.442C276.852 120.012 276.421 121.154 276.418 121.784Z" fill="#1F1F1F"/>
        <path d="M299.424 98.646C299.217 100.431 299.81 102.524 300.98 104.203C302.161 105.878 303.687 107.165 305.702 108.185C306.844 108.759 307.315 108.926 308.261 109.083C309.753 109.328 311.16 109.16 312.299 108.599C313.093 108.209 313.626 107.765 314.041 107.121C315 105.668 314.992 103.355 314.021 101.199C313.401 99.8083 311.957 98.0613 310.57 97.0285C309.3 96.075 307.545 95.2308 306.245 94.9386C305.086 94.6778 303.687 94.7042 302.644 95.0019C302.282 95.1044 301.459 95.5079 301.078 95.7637C300.171 96.3708 299.556 97.4433 299.424 98.646Z" fill="#1F1F1F"/>
        <path d="M287.823 136.119C287.489 138.045 288.216 139.832 289.764 140.887C290.937 141.679 292.28 142.226 293.697 142.477C296.2 142.925 298.697 142.603 300.815 141.555C302.263 140.834 303.223 140.045 304.133 138.831C305.199 137.391 305.298 135.833 304.432 134.248C303.694 132.897 302.723 132.148 300.736 131.402C299.847 131.072 299.512 130.975 298.729 130.857C296.277 130.474 293.995 130.82 291.813 131.894C290.348 132.618 289.098 133.605 288.519 134.498C288.259 134.891 287.881 135.779 287.823 136.119Z" fill="#1F1F1F"/>
        <path d="M303.273 120.622C302.569 124.151 303.417 128.104 305.543 131.127C307.338 133.687 309.36 135.876 311.521 137.584C312.965 138.732 314.973 139.988 315.828 140.275C317.767 140.925 320.109 140.328 321.377 138.859C321.862 138.294 322.249 137.521 322.44 136.737C322.792 135.329 322.854 134.311 322.749 131.526C322.637 128.547 322.679 127.802 323.023 126.158C323.489 123.909 324.275 122.566 326.686 119.858C328.815 117.462 329.214 116.893 329.607 115.722C330.342 113.53 329.781 111.392 328.178 110.263C327.594 109.859 325.99 109.305 325.19 109.237C323.17 109.063 321.019 109.261 318.555 109.841C313.028 111.145 309.757 112.486 307.113 114.533C305.938 115.441 305.09 116.396 304.41 117.591C304.052 118.206 303.421 119.897 303.273 120.622Z" fill="#1F1F1F"/>
      </motion.g>

      <motion.g
        initial='hidden'
        animate={controls}
        variants={pathVariants}
        custom={4}
      >
        <path d="M252.11 40.4375C251.988 43.5675 254.076 46.6352 257.672 48.611C260.795 50.3271 263.866 50.7091 266.465 49.7121C268.261 49.0199 269.622 47.413 269.737 45.853C269.777 45.2699 269.567 44.037 269.299 43.2702C268.562 41.189 266.962 39.2987 264.827 37.9791C263.657 37.2552 262.809 36.8946 261.328 36.4784C259.322 35.9138 258.266 35.8134 256.84 36.0578C255.43 36.2995 254.271 36.8577 253.419 37.7026C252.633 38.4889 252.149 39.5044 252.11 40.4375Z" fill="#1F1F1F"/>
        <path d="M246.977 57.3405C246.759 59.075 247.82 61.1382 249.618 62.4712C251.112 63.5822 253.826 64.4717 256.073 64.5957C259.822 64.7927 262.96 63.5677 264.829 61.1749C265.236 60.6593 265.538 59.8484 265.59 59.15C265.742 56.9662 264.352 54.8634 261.793 53.427C260.984 52.9673 259.087 52.3336 258.005 52.1618C253.759 51.4754 250.043 52.5672 247.988 55.1001C247.653 55.5113 247.091 56.4948 247.052 56.7447C247.043 56.7737 247.015 57.0426 246.977 57.3405Z" fill="#1F1F1F"/>
        <path d="M269.544 33.5876C268.962 36.9706 271.008 40.6526 274.717 42.902C275.766 43.5322 277.083 44.0951 277.961 44.2827C279.613 44.6333 281.163 44.4993 282.41 43.8892C283.909 43.1593 284.653 42.1534 284.863 40.5676C285.291 37.3265 283.094 33.6688 279.476 31.5832C278.636 31.0977 277.271 30.5357 276.458 30.3445C274.331 29.8372 272.044 30.2964 270.738 31.4996C270.137 32.05 269.663 32.8784 269.544 33.5876Z" fill="#1F1F1F"/>
        <path d="M258.582 72.2814C258.456 73.5684 258.964 74.9899 259.852 75.8795C261.012 77.0446 263.224 77.9673 265.386 78.1839C266.949 78.3397 268.969 78.0988 270.388 77.5981C273.319 76.5543 275.662 74.1671 275.827 72.0429C275.881 71.3373 275.708 70.6478 275.266 69.8027C274.484 68.2952 273.335 67.4511 271.142 66.7485C268.823 66.0147 266.373 66.0574 263.956 66.8809C263.058 67.1874 261.606 67.9715 260.926 68.5209C259.403 69.7354 258.714 70.8458 258.582 72.2814Z" fill="#1F1F1F"/>
        <path d="M273.745 56.1832C273.322 58.6476 273.652 61.2444 274.705 63.7895C275.161 64.8932 275.707 65.7867 276.758 67.1479C279.2 70.3266 281.583 72.502 284.704 74.4003C285.782 75.0635 286.036 75.1907 286.73 75.408C287.366 75.6072 287.643 75.662 288.12 75.6604C289.984 75.6644 291.525 74.9242 292.464 73.5743C292.763 73.1518 292.867 72.9222 293.1 72.1773C293.578 70.6513 293.637 69.7011 293.449 66.3707C293.286 63.3418 293.306 62.7207 293.635 61.0609C294.074 58.8478 294.85 57.4866 297.332 54.5791C299.394 52.1516 299.766 51.5695 300.09 50.2573C300.432 48.8873 300.332 47.7842 299.769 46.6945C299.153 45.4931 298.291 44.8738 296.663 44.4436C294.323 43.83 291.327 44.1704 286.414 45.6103C282.448 46.7666 279.697 48.0732 277.527 49.8316C276.356 50.7831 275.572 51.6892 274.892 52.8737C274.558 53.4599 273.868 55.4355 273.745 56.1832Z" fill="#1F1F1F"/>
      </motion.g>

      <motion.g
        initial='hidden'
        animate={controls}
        variants={pathVariants}
        custom={5}
      >
        <path d="M190.099 88.3419C190.162 89.7436 190.496 90.7896 191.251 91.9984C192.535 94.0581 194.633 95.6424 197.461 96.684C199.538 97.4451 201.459 97.6232 203.345 97.2247C204.271 97.0329 204.758 96.8531 205.492 96.4208C206.824 95.6402 207.649 94.6298 207.943 93.3989C208.107 92.7134 208.09 92.1952 207.89 91.3371C207.188 88.3412 204.633 85.7362 201.067 84.3623C199.611 83.8042 197.376 83.4106 196.004 83.4803C193.775 83.5866 191.752 84.6538 190.71 86.275C190.388 86.7746 190.072 87.8367 190.099 88.3419Z" fill="#1F1F1F"/>
        <path d="M186.141 106.01C186.303 107.942 187.71 109.845 189.779 110.931C190.062 111.084 190.782 111.365 191.374 111.561C194.928 112.722 198.91 112.356 201.729 110.599C202.854 109.901 203.945 108.791 204.465 107.816C205.212 106.421 204.818 104.254 203.557 102.816C202.352 101.437 200.773 100.546 198.607 100.028C197.325 99.7219 196.276 99.6117 195.016 99.6379C191.329 99.7155 188.416 101.116 186.811 103.599C186.416 104.206 186.089 105.382 186.141 106.01Z" fill="#1F1F1F"/>
        <path d="M206.997 80.9173C206.95 82.7136 207.726 84.7456 209.041 86.3142C210.366 87.8772 212.001 89.0234 214.099 89.8596C215.287 90.3304 215.771 90.454 216.728 90.5266C218.236 90.6374 219.622 90.3452 220.706 89.6849C221.462 89.2266 221.953 88.7362 222.31 88.0579C223.136 86.5254 222.922 84.2227 221.763 82.1617C221.022 80.8315 219.428 79.2198 217.955 78.3146C216.604 77.4778 214.782 76.7931 213.461 76.6178C212.283 76.4612 210.892 76.6119 209.88 77.0012C209.528 77.1355 208.745 77.6106 208.388 77.8993C207.539 78.5847 207.021 79.7077 206.997 80.9173Z" fill="#1F1F1F"/>
        <path d="M198.778 119.274C198.616 121.222 199.499 122.937 201.135 123.85C202.373 124.535 203.76 124.96 205.194 125.084C207.727 125.308 210.185 124.765 212.201 123.533C213.58 122.685 214.465 121.814 215.264 120.524C216.198 118.994 216.158 117.434 215.154 115.932C214.299 114.653 213.265 113.993 211.219 113.426C210.305 113.177 209.963 113.111 209.172 113.062C206.695 112.899 204.454 113.446 202.376 114.711C200.981 115.562 199.823 116.657 199.326 117.598C199.103 118.012 198.805 118.93 198.778 119.274Z" fill="#1F1F1F"/>
        <path d="M212.785 102.464C212.398 106.042 213.594 109.903 215.981 112.725C217.996 115.116 220.206 117.116 222.509 118.624C224.05 119.639 226.162 120.712 227.039 120.922C229.028 121.397 231.307 120.594 232.439 119.017C232.872 118.412 233.189 117.607 233.309 116.81C233.535 115.376 233.506 114.356 233.154 111.592C232.778 108.634 232.753 107.889 232.949 106.221C233.214 103.939 233.877 102.532 236.037 99.6194C237.945 97.0443 238.292 96.4415 238.579 95.24C239.116 92.992 238.366 90.9119 236.669 89.9299C236.052 89.5799 234.405 89.1709 233.602 89.1739C231.575 89.1806 229.449 89.5689 227.047 90.3662C221.658 92.1565 218.52 93.7833 216.068 96.0571C214.978 97.0667 214.219 98.093 213.648 99.3434C213.346 99.9881 212.868 101.728 212.785 102.464Z" fill="#1F1F1F"/>
      </motion.g>

      <motion.g
        initial='hidden'
        animate={controls}
        variants={pathVariants}
        custom={6}
      >
        <path d="M160.353 33.3583C160.649 36.4767 163.128 39.2383 166.956 40.7164C170.279 42.0005 173.375 41.9692 175.817 40.6344C177.505 39.7086 178.639 37.9346 178.545 36.3731C178.506 35.7899 178.134 34.596 177.766 33.8718C176.758 31.9077 174.92 30.2477 172.628 29.2248C171.372 28.6636 170.484 28.4193 168.96 28.2045C166.896 27.9127 165.836 27.9542 164.456 28.3867C163.091 28.8142 162.017 29.5221 161.285 30.4733C160.611 31.3573 160.266 32.4285 160.353 33.3583Z" fill="#1F1F1F"/>
        <path d="M157.518 50.7954C157.534 52.5434 158.86 54.4466 160.819 55.5278C162.448 56.4295 165.257 56.9488 167.501 56.7718C171.242 56.4667 174.189 54.8339 175.721 52.2131C176.056 51.6477 176.247 50.8039 176.205 50.1048C176.064 47.9202 174.407 46.0218 171.679 44.9396C170.816 44.592 168.851 44.2171 167.756 44.1912C163.456 44.0777 159.92 45.6556 158.221 48.4401C157.944 48.8924 157.518 49.942 157.513 50.1949C157.508 50.2247 157.515 50.4951 157.518 50.7954Z" fill="#1F1F1F"/>
        <path d="M176.714 24.2433C176.588 27.6737 179.108 31.0498 183.084 32.784C184.208 33.2685 185.587 33.6508 186.483 33.7195C188.167 33.8465 189.686 33.5068 190.84 32.7357C192.228 31.8124 192.83 30.7162 192.828 29.1165C192.819 25.8474 190.153 22.5156 186.29 20.9314C185.392 20.5624 183.965 20.1874 183.133 20.1065C180.957 19.8877 178.752 20.648 177.619 22.0147C177.097 22.6403 176.738 23.5245 176.714 24.2433Z" fill="#1F1F1F"/>
        <path d="M171.013 64.0539C171.061 65.346 171.754 66.6871 172.753 67.4502C174.058 68.4501 176.373 69.0694 178.545 68.9955C180.115 68.9413 182.085 68.4329 183.424 67.7473C186.189 66.3217 188.193 63.6432 188.073 61.5161C188.032 60.8095 187.769 60.1493 187.218 59.3707C186.242 57.9811 184.99 57.2979 182.723 56.8943C180.327 56.4764 177.905 56.8458 175.619 57.9844C174.77 58.408 173.436 59.3789 172.835 60.0142C171.487 61.421 170.953 62.6134 171.013 64.0539Z" fill="#1F1F1F"/>
        <path d="M183.892 46.0764C183.803 48.5751 184.476 51.1047 185.859 53.4865C186.459 54.5195 187.119 55.3321 188.342 56.541C191.186 59.3653 193.838 61.2033 197.184 62.6681C198.342 63.1815 198.61 63.2736 199.327 63.3963C199.984 63.5088 200.266 63.5263 200.738 63.4611C202.586 63.2162 204.015 62.2769 204.765 60.8138C205.005 60.3552 205.077 60.1138 205.209 59.3445C205.479 57.7684 205.411 56.8188 204.78 53.5432C204.215 50.5632 204.151 49.945 204.256 48.2561C204.396 46.0042 204.982 44.5516 207.054 41.339C208.774 38.658 209.066 38.0313 209.211 36.6877C209.367 35.2843 209.121 34.2044 208.417 33.1996C207.646 32.0912 206.709 31.5925 205.039 31.3834C202.638 31.0876 199.714 31.8248 195.038 33.9073C191.261 35.5826 188.71 37.2446 186.794 39.2768C185.76 40.3761 185.104 41.3787 184.588 42.6434C184.335 43.269 183.914 45.319 183.892 46.0764Z" fill="#1F1F1F"/>
      </motion.g>

      <motion.g
        initial='hidden'
        animate={controls}
        variants={pathVariants}
        custom={7}
      >
        <path d="M102.329 91.8654C102.607 93.2409 103.098 94.223 104.03 95.3012C105.615 97.1388 107.933 98.3812 110.887 98.9753C113.056 99.4077 114.981 99.2882 116.784 98.604C117.669 98.2721 118.123 98.0195 118.782 97.4793C119.977 96.5032 120.637 95.3777 120.739 94.1162C120.795 93.4137 120.699 92.9043 120.369 92.0872C119.214 89.235 116.288 87.0543 112.553 86.2456C111.029 85.9181 108.76 85.8732 107.415 86.1531C105.229 86.6013 103.394 87.9671 102.614 89.7292C102.373 90.2725 102.224 91.3705 102.329 91.8654Z" fill="#1F1F1F"/>
        <path d="M101.14 109.932C101.597 111.816 103.28 113.479 105.492 114.234C105.795 114.342 106.549 114.509 107.164 114.611C110.855 115.211 114.733 114.237 117.248 112.067C118.252 111.205 119.159 109.939 119.523 108.897C120.046 107.403 119.323 105.323 117.856 104.095C116.453 102.918 114.756 102.281 112.536 102.103C111.222 101.997 110.169 102.05 108.928 102.27C105.297 102.914 102.634 104.745 101.43 107.447C101.134 108.107 100.991 109.32 101.14 109.932Z" fill="#1F1F1F"/>
        <path d="M117.884 81.9286C118.114 83.7108 119.194 85.5991 120.735 86.9466C122.284 88.2871 124.076 89.1681 126.277 89.6715C127.524 89.9539 128.022 90.0013 128.978 89.9259C130.485 89.8034 131.809 89.3014 132.779 88.482C133.456 87.9128 133.866 87.3527 134.114 86.6275C134.694 84.9863 134.128 82.7439 132.666 80.8857C131.729 79.6855 129.906 78.3382 128.311 77.6706C126.848 77.0516 124.942 76.6555 123.609 76.6856C122.421 76.7121 121.07 77.0751 120.13 77.6155C119.803 77.8022 119.102 78.3923 118.794 78.7325C118.06 79.5404 117.722 80.7296 117.884 81.9286Z" fill="#1F1F1F"/>
        <path d="M115.667 121.094C115.807 123.043 116.944 124.602 118.701 125.252C120.03 125.739 121.465 125.945 122.902 125.847C125.438 125.678 127.784 124.763 129.586 123.235C130.818 122.186 131.559 121.189 132.15 119.792C132.837 118.136 132.557 116.601 131.334 115.271C130.292 114.139 129.169 113.646 127.061 113.401C126.119 113.295 125.771 113.282 124.982 113.356C122.509 113.576 120.379 114.462 118.52 116.031C117.273 117.087 116.298 118.346 115.951 119.353C115.794 119.796 115.642 120.749 115.667 121.094Z" fill="#1F1F1F"/>
        <path d="M126.921 102.328C127.089 105.922 128.865 109.554 131.658 111.975C134.017 114.027 136.508 115.663 139.016 116.799C140.695 117.565 142.947 118.3 143.845 118.372C145.884 118.536 148.013 117.391 148.889 115.659C149.223 114.994 149.412 114.151 149.409 113.344C149.411 111.892 149.225 110.889 148.452 108.212C147.625 105.348 147.486 104.615 147.423 102.937C147.333 100.642 147.772 99.1487 149.458 95.9388C150.947 93.1008 151.197 92.4518 151.296 91.2205C151.48 88.9165 150.42 86.9766 148.592 86.2675C147.928 86.0166 146.237 85.8659 145.444 85.9925C143.443 86.311 141.402 87.0218 139.151 88.1794C134.102 90.7777 131.251 92.868 129.179 95.492C128.257 96.6573 127.665 97.7882 127.293 99.1116C127.094 99.7951 126.889 101.588 126.921 102.328Z" fill="#1F1F1F"/>
      </motion.g>

      <motion.g
        initial='hidden'
        animate={controls}
        variants={pathVariants}
        custom={8}
      >
        <path d="M79.2177 15.4471C78.5668 18.5112 80.1049 21.8881 83.3151 24.4445C86.1016 26.6646 89.0642 27.5613 91.7939 27.0188C93.6817 26.6408 95.2946 25.2875 95.6725 23.7696C95.8103 23.2016 95.8128 21.951 95.6785 21.1499C95.304 18.9739 94.0481 16.84 92.1674 15.1779C91.1364 14.2663 90.3621 13.7673 88.9727 13.1063C87.091 12.2101 86.0672 11.9323 84.6203 11.9316C83.1905 11.9311 81.9535 12.285 80.9707 12.9734C80.063 13.6152 79.4133 14.534 79.2177 15.4471Z" fill="#1F1F1F"/>
        <path d="M71.2946 31.2365C70.7864 32.9091 71.4824 35.1221 73.0281 36.7403C74.3123 38.0882 76.8367 39.4246 79.0307 39.9274C82.6919 40.7564 85.9922 40.0805 88.239 38.0387C88.7279 37.5995 89.1624 36.8515 89.3319 36.1719C89.8514 34.0454 88.8379 31.7377 86.5592 29.8886C85.84 29.2986 84.0774 28.3527 83.0408 28.0002C78.9718 26.6047 75.1249 27.0515 72.6704 29.1997C72.2705 29.5482 71.5501 30.4223 71.4698 30.6621C71.4559 30.6891 71.3822 30.9493 71.2946 31.2365Z" fill="#1F1F1F"/>
        <path d="M97.5576 11.6486C96.4102 14.884 97.8034 18.8594 101.078 21.7045C102.006 22.5032 103.207 23.281 104.041 23.6145C105.61 24.2399 107.161 24.3704 108.493 23.9802C110.094 23.5147 110.997 22.6492 111.473 21.1221C112.443 18.0002 110.897 14.0232 107.685 11.355C106.939 10.7343 105.69 9.94933 104.921 9.6232C102.91 8.76297 100.578 8.82816 99.0875 9.79293C98.402 10.2335 97.7948 10.9698 97.5576 11.6486Z" fill="#1F1F1F"/>
        <path d="M80.2008 47.9266C79.8595 49.1738 80.119 50.6607 80.8439 51.6879C81.7893 53.0325 83.8127 54.3164 85.9077 54.8962C87.4215 55.3144 89.4534 55.4191 90.9367 55.1659C94.0018 54.6335 96.7152 52.6776 97.2375 50.6121C97.4105 49.9258 97.3566 49.217 97.0642 48.3092C96.5486 46.691 95.5593 45.6646 93.5167 44.6007C91.356 43.4848 88.9337 43.1119 86.4121 43.5142C85.4754 43.6642 83.9114 44.1911 83.1481 44.6174C81.4411 45.5563 80.574 46.534 80.2008 47.9266Z" fill="#1F1F1F"/>
        <path d="M97.8699 34.6287C97.0363 36.986 96.9219 39.6012 97.5284 42.2879C97.7911 43.4529 98.1778 44.4259 98.9824 45.9453C100.851 49.4916 102.831 52.0393 105.585 54.4387C106.536 55.2749 106.765 55.4432 107.412 55.7749C108.005 56.079 108.268 56.1799 108.739 56.259C110.575 56.5787 112.22 56.1103 113.374 54.9388C113.74 54.573 113.881 54.3643 114.237 53.6697C114.966 52.2467 115.186 51.3203 115.565 48.0062C115.917 44.9934 116.042 44.3846 116.647 42.8045C117.454 40.6977 118.449 39.4875 121.388 37.0424C123.831 34.9991 124.297 34.4886 124.838 33.2501C125.407 31.9577 125.496 30.8538 125.125 29.6843C124.721 28.3959 123.977 27.6397 122.445 26.94C120.243 25.939 117.232 25.767 112.147 26.3542C108.043 26.8222 105.11 27.644 102.674 29.0095C101.358 29.749 100.432 30.5092 99.5611 31.5614C99.1323 32.0824 98.1177 33.9127 97.8699 34.6287Z" fill="#1F1F1F"/>
      </motion.g>

      <motion.g
        initial='hidden'
        animate={controls}
        variants={pathVariants}
        custom={9}
      >
        <path d="M15.1862 52.8615C14.9138 54.2379 14.9901 55.3334 15.4362 56.6869C16.194 58.9927 17.856 61.0301 20.3554 62.7138C22.1921 63.9465 24.015 64.5757 25.9423 64.6368C26.8873 64.6705 27.403 64.6115 28.2189 64.366C29.6976 63.9241 30.7396 63.1387 31.3176 62.0129C31.6395 61.3859 31.7467 60.8787 31.7562 59.9976C31.7861 56.9207 29.9225 53.7831 26.7848 51.6013C25.5034 50.7134 23.426 49.8002 22.0768 49.5419C19.8858 49.1155 17.6671 49.6715 16.2703 50.9988C15.839 51.4077 15.2797 52.3643 15.1862 52.8615Z" fill="#1F1F1F"/>
        <path d="M7.14791 69.0843C6.84595 70.9993 7.76094 73.1813 9.51294 74.7283C9.75118 74.9443 10.3834 75.3882 10.9118 75.7191C14.0891 77.6912 18.0437 78.282 21.1994 77.2444C22.4575 76.8341 23.7811 76.0144 24.5185 75.1919C25.5749 74.0135 25.7071 71.8154 24.8241 70.1184C23.9811 68.4929 22.6595 67.2521 20.6781 66.2348C19.5054 65.6327 18.5131 65.2765 17.2827 65.0026C13.6827 64.202 10.5209 64.8701 8.37101 66.9011C7.84384 67.3971 7.24613 68.4618 7.14791 69.0843Z" fill="#1F1F1F"/>
        <path d="M33.3674 49.6645C32.8946 51.3981 33.166 53.5564 34.0712 55.3926C34.9866 57.2256 36.302 58.7273 38.1412 60.0379C39.1836 60.7776 39.625 61.0127 40.5366 61.3104C41.975 61.7763 43.3909 61.8218 44.6013 61.4381C45.4445 61.1725 46.038 60.8127 46.5457 60.2386C47.7117 58.9461 48.0516 56.6588 47.4155 54.3814C47.0112 52.9131 45.8463 50.969 44.6296 49.7395C43.5169 48.606 41.9091 47.5079 40.6674 47.0237C39.5607 46.5918 38.1735 46.4076 37.0979 46.5454C36.7246 46.5923 35.8508 46.8677 35.4353 47.0633C34.4477 47.5273 33.6782 48.4952 33.3674 49.6645Z" fill="#1F1F1F"/>
        <path d="M16.2684 84.9702C15.6486 86.8243 16.0993 88.6998 17.4713 89.975C18.5114 90.9349 19.757 91.6767 21.121 92.1385C23.528 92.9569 26.045 93.014 28.2959 92.2956C29.8362 91.8001 30.9036 91.1641 31.9859 90.1012C33.2566 88.837 33.5882 87.3121 32.9696 85.6144C32.4431 84.1685 31.5954 83.2818 29.743 82.2455C28.9144 81.786 28.5975 81.6403 27.841 81.4053C25.4739 80.6584 23.1664 80.6577 20.8475 81.3924C19.2903 81.8877 17.9061 82.6762 17.1995 83.472C16.884 83.8211 16.377 84.6426 16.2684 84.9702Z" fill="#1F1F1F"/>
        <path d="M33.8705 71.9696C32.6446 75.3525 32.8889 79.388 34.5372 82.6962C35.9266 85.4966 37.5975 87.9645 39.4766 89.9771C40.7326 91.3291 42.5287 92.8731 43.3307 93.2849C45.1503 94.2191 47.5553 93.9802 49.0293 92.7181C49.5937 92.2327 50.0926 91.5263 50.3989 90.7805C50.9589 89.441 51.173 88.4435 51.4876 85.6746C51.8246 82.7128 51.9782 81.9824 52.5644 80.4093C53.3637 78.2555 54.3425 77.0458 57.1324 74.7301C59.5976 72.6821 60.0774 72.1788 60.6416 71.08C61.6973 69.0238 61.4636 66.8253 60.0484 65.4683C59.5318 64.9816 58.0291 64.193 57.2484 64.0051C55.2782 63.5302 53.1211 63.4023 50.598 63.6061C44.9378 64.0648 41.5031 64.8995 38.5816 66.5258C37.2829 67.2475 36.3016 68.0641 35.4496 69.143C35.0031 69.6974 34.1254 71.2742 33.8705 71.9696Z" fill="#1F1F1F"/>
      </motion.g>
    </svg>
  );
};