// extracted by mini-css-extract-plugin
export var root = "MyProject-module--root--lVzck";
export var headingIllustration = "MyProject-module--headingIllustration--1R5mf";
export var finalButton = "MyProject-module--finalButton--2N9hG";
export var buttonArrow = "MyProject-module--buttonArrow--27ZoN";
export var bookBlock = "MyProject-module--bookBlock--3WeZA";
export var bookTextFirst = "MyProject-module--bookTextFirst--2aVbo";
export var bookImage = "MyProject-module--bookImage--3PoxC";
export var bookTextSecond = "MyProject-module--bookTextSecond--2SwOT";
export var bookButton = "MyProject-module--bookButton--2ExWi";
export var mobile = "MyProject-module--mobile--St7yI";