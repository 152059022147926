// extracted by mini-css-extract-plugin
export var root = "About-module--root--3evMw";
export var headingIllustration = "About-module--headingIllustration--l6Ais";
export var general = "About-module--general--2S_LL";
export var generalInfo = "About-module--generalInfo--1rqtx";
export var generalContent = "About-module--generalContent--2PFOj";
export var worldMap = "About-module--worldMap--1fbJn";
export var generalImage = "About-module--generalImage---Pti5";
export var experience = "About-module--experience--2A13y";
export var experienceHeader = "About-module--experienceHeader--2h7kM";
export var final = "About-module--final--3e7cX";
export var finalFirst = "About-module--finalFirst--23ogM";
export var finalSecond = "About-module--finalSecond--2iEed";
export var finalThird = "About-module--finalThird--100Js";
export var finalFourth = "About-module--finalFourth--fhmiC";
export var finalButton = "About-module--finalButton--LaFYp";
export var buttonArrow = "About-module--buttonArrow--1dFuJ";
export var mobile = "About-module--mobile--19KPs";
export var light = "About-module--light--1rq6D";
export var lightImage = "About-module--lightImage--1NvgF";
export var darkImage = "About-module--darkImage--3jry1";
export var generalImageDark = "About-module--generalImageDark--stQ8O";
export var dark = "About-module--dark--ujRGu";